import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import "survey-react/defaultV2.css";





function DefaultContent(props) {


    return (
        <div className="row mx-4 text-white">
            <div className="surveytitle px-4 py-4">
                No Mission Selected
            </div>
            <div className="surveyComplete d-flex flex-column align-items-center justify-content-center my-4 py-5">
                <div className="pt-4">
                    No mission selected. Please go back to missions and choose one.
                </div>
                <Link to={{ pathname: "/missions" }}
                    className="btn btn-md btn-primary-color btn-shadow text-white no-outline px-3 my-3">
                    Back to Missions
                </Link>
            </div>
        </div>
    )

}


export default DefaultContent