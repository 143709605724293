import React, { useEffect, useState, useContext } from "react";
import { useAlert } from 'react-alert';
import { UserContext } from "../../UserContext";
import CardWallets from "../components/profile_components/WalletsCard"

import { UserPortalContainer, AccountColumn } from "../components/LayoutComponents";
import HeaderUser from "../components/menu_components/HeaderUser";

// API Calls
import UserWalletAPI from "../api/api_calls/UserWalletAPI";


function Wallets(props) {
    const { user, setUser, refreshUser } = useContext(UserContext);

    const alert = useAlert();
    const [wallets, setWallets] = useState([]);
    const [refreshWallets, setRefreshWallets] = useState(0);
    const [loadComplete, setLoadComplete] = useState(false);

    useEffect(() => {
        // Grab extended profile info on this user
        const getUserWallets = async () => {
            const data = await UserWalletAPI.index();

            if (data.success) {

                setWallets(data.user_wallets)
                setLoadComplete(true);
            } else {
                alert.show("Unable to load wallets. Try again later", { type: 'error' })
            }
        }

        getUserWallets();
    }, [refreshWallets]);

    function UpdateWallets() {
        setRefreshWallets(refreshWallets + 1)
    }


    return (
        <>
            <HeaderUser />
            <UserPortalContainer>
                <AccountColumn />
                <div className="col px-2">
                    <CardWallets wallets={wallets}
                        loaded={loadComplete}
                        updateWallets={UpdateWallets}
                    />
                </div>
            </UserPortalContainer>
        </>

    )
}


export default Wallets;