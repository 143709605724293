import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TextEntryQuestion, MultipleChoiceQuestion, RadioOption, CheckboxOption, DropdownQuestion, DropdownOption, LogInCard, ErrorMessage, NoticeMessage } from "../preauth_portal/components/CommonComponents";
import { SlideContainer, Slide } from "../preauth_portal/components/SliderComponents"
import { UserContext } from "../UserContext";


import TimezoneList from "../preauth_portal/components/TimezoneList";
import IsValidEVMAddress from "../utility_components/IsValidAddress";

// Api Calls
import OnboardingAPI from "./api/api_calls/OnboardingAPI";


function SignUpQuestions(props) {

  const [error, setError] = useState("");
  const [notice, setNotice] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser, refreshUser } = useContext(UserContext);

  const [showUserWalletQuestions, setShowUserWalletQuestions] = useState(true)
  const [countryList, setCountryList] = useState([])

  const [walletAddress, setWalletAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [interest, setInterest] = useState(null)
  const [familiar, setFamiliar] = useState(null)
  const [hasWallet, setHasWallet] = useState(null)
  const [country, setCountry] = useState(null)
  const [timezone, setTimezone] = useState(null)

  // Activities
  const [activities_cex_bought_sold, setActivities_cex_bought_sold] = useState(false)
  const [activities_contracts_created, setActivities_contracts_created] = useState(false)
  const [activities_personal_wallet, setActivities_personal_wallet] = useState(false)
  const [activities_nfts_bought_sold, setActivities_nfts_bought_sold] = useState(false)
  const [activities_nfts_created, setActivities_nfts_created] = useState(false)
  const [activities_defi_used, setActivities_defi_used] = useState(false)
  const [activities_crypto_sent, setActivities_crypto_sent] = useState(false)
  const [activities_crypto_bridged, setActivities_crypto_bridged] = useState(false)
  const [activities_crypto_staked, setActivities_crypto_staked] = useState(false)
  const [activities_none, setActivities_none] = useState(false)

  // User Types.
  const [userTypeList, setUserTypeList] = useState([]);

  const [holder, setHolder] = useState(false);
  const [creatorArtContent, setCreatorArtContent] = useState(false);
  const [socialMediaUser, setSocialMediaUser] = useState(false);
  const [nftTrader, setNftTrader] = useState(false);
  const [memecoinTrader, setMemecoinTrader] = useState(false);
  const [occasionalDefiTrader, setOccasionalDefiTrader] = useState(false);
  const [heavyDefiTrader, setHeavyDefiTrader] = useState(false);
  const [onChainGamer, setOnChainGamer] = useState(false);
  const [daoParticipant, setDaoParticipant] = useState(false);
  const [developer, setDeveloper] = useState(false);
  const [newToBlockchain, setNewToBlockchain] = useState(false);
  const [noneOfTheAbove, setNoneOfTheAbove] = useState(false);

  const [highlightPage, setHighlightPage] = useState(-1)
  const titleRef = useRef(null)
  const errorRef = useRef(null)





  // ------------------------------------------------------ //
  // ------------------------------------------------------ //
  // ------------------ UseEffect Function ---------------- //
  // ------------------------------------------------------ //
  // ------------------------------------------------------ //


  // Check that the user is in the right place, whether they have a wallet, and get the country list
  useEffect(() => {
    if (location.state) {
      const tempNotice = location.state.notice || "";
      setNotice(tempNotice)
    }
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)

    // Get the list of countries we accept.
    const GetCountries = async () => {
      const result = await OnboardingAPI.getCountries(navigate);

      if (result.success) {
        setCountryList(result.countries)
      } else {
        // sets the message to display to user
        setError("Could not fetch countries. Please try again later.")
      }
    }

    const GetUserTypes = async () => {
      const result = await OnboardingAPI.getUserTypes(navigate);

      if (result.success) {
        setUserTypeList(result)
      } else {
        // sets the message to display to user
        setError("Could not fetch user types. Please try again later.")
      }
    }

    // Check if the user has a wallet set up for this new account
    const CheckWallet = async () => {
      const result = await OnboardingAPI.userHasWallet(navigate);

      if (result.success) {
        // Show the wallet questions if they DONT have a wallet.
        setShowUserWalletQuestions(!result.has_wallet)
      } else {
        console.log("Error checking on wallet ownership")
      }
    }

    CheckWallet();
    GetUserTypes();
    GetCountries();
  }, []);

  // ------------------------------------------------------ //
  // ------------------------------------------------------ //
  // ------------------ Submit Function ------------------ //
  // ------------------------------------------------------ //
  // ------------------------------------------------------ //

  // Submit function
  function handleSubmit(event) {
    // Handle form submission here EXPAND TO UNCOMMENT CONSOLE LOGS
    // console.log("Submitted")
    // console.log("walletAddress", walletAddress)
    // console.log("firstName", firstName)
    // console.log("interest", interest)
    // console.log("familiar", familiar)
    // console.log("hasWallet", hasWallet)
    // console.log("country", country)
    // console.log("timezone", timezone)
    // console.log("activities_cex_bought_sold", activities_cex_bought_sold)
    // console.log("activities_contracts_created", activities_contracts_created)
    // console.log("activities_personal_wallet", activities_personal_wallet)
    // console.log("activities_nfts_bought_sold", activities_nfts_bought_sold)
    // console.log("activities_nfts_created", activities_nfts_created)
    // console.log("activities_defi_used", activities_defi_used)
    // console.log("activities_crypto_sent", activities_crypto_sent)
    // console.log("activities_crypto_bridged", activities_crypto_bridged)
    // console.log("activities_crypto_staked", activities_crypto_staked)
    // console.log("activities_none", activities_none)


    // The log in function called on submit
    event.preventDefault();
    setError([]);
    setNotice("");

    if (!NoneSelectedActivities()) { setError("Please fill out your activities"); setHighlightPage(1); return; }
    if (!interest || !familiar) { setError("Please fill out your interest and familiarity"); setHighlightPage(2); return; }
    if (!NoneSelectedUserTypes()) { setError("Please fill out your user types"); setHighlightPage(3); return; }
    if (showUserWalletQuestions && !hasWallet) { setError("Please answer whether you have a wallet."); setHighlightPage(walletPageIndex); return; }
    if (walletAddress && !IsValidEVMAddress(walletAddress)) { setError("Please enter a valid wallet address or leave it blank."); setHighlightPage(walletPageIndex); return; }
    if (!country) { setError("Please fill out your country"); setHighlightPage(countryAndNamePageIndex); return; }
    if (!firstName) { setError("Please fill out your first name or pseudonym"); setHighlightPage(countryAndNamePageIndex); return; }

    const tempHasWallet = hasWallet ?? !showUserWalletQuestions // If hasWallet isn't null, use it. Otherwise, use the inverse of showUserWalletQuestions (which is false if they DO have a wallet)

    const user_hash = {
      first_name: firstName,
      sign_up_user_agent: navigator.userAgent,
    }
    const crypto_usage_hash = {
      activities_cex_bought_sold: activities_cex_bought_sold,
      activities_contracts_created: activities_contracts_created,
      activities_personal_wallet: activities_personal_wallet,
      activities_nfts_bought_sold: activities_nfts_bought_sold,
      activities_nfts_created: activities_nfts_created,
      activities_defi_used: activities_defi_used,
      activities_crypto_sent: activities_crypto_sent,
      activities_crypto_bridged: activities_crypto_bridged,
      activities_crypto_staked: activities_crypto_staked,
      activities_none: activities_none,
      familiar: parseInt(familiar),
      interest: parseInt(interest),
      has_wallet: tempHasWallet,
      eth_wallet: walletAddress,
    }
    const user_type_hash = {
      holder: holder,
      creator_art_content: creatorArtContent,
      social_media_user: socialMediaUser,
      nft_trader: nftTrader,
      memecoin_trader: memecoinTrader,
      occasional_defi_trader: occasionalDefiTrader,
      heavy_defi_trader: heavyDefiTrader,
      on_chain_gamer: onChainGamer,
      dao_participant: daoParticipant,
      developer: developer,
      new_to_blockchain: newToBlockchain,
      none_of_the_above: noneOfTheAbove
    };

    const user_demographic_hash = {
      country_id: country,
      timezone: timezone,
      original_browser_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    const log_hash = {
      user_agent: navigator.userAgent,
      window_outer_width: window.outerWidth,
      window_outer_height: window.outerHeight,
      window_inner_width: window.innerWidth,
      window_inner_height: window.innerHeight,
      screen_width: window.screen.width,
      screen_height: window.screen.height
    }


    const CreateProfile = async (user_hash, crypto_usage_hash, user_demographic_hash, user_type_hash, log_hash) => {
      const result = await OnboardingAPI.create(user_hash, crypto_usage_hash, user_demographic_hash, user_type_hash, log_hash, navigate);

      if (result.success) {
        refreshUser();

        navigate("/dashboard")
      } else {
        // sets the message to display to user
        setError(result.failureReason);
        setNotice("")
      }
    }

    CreateProfile(user_hash, crypto_usage_hash, user_demographic_hash, user_type_hash, log_hash)
  };

  // ------------------------------------------------------ //
  // ------------------------------------------------------ //
  // ------------------ Helper Functions ------------------ //
  // ------------------------------------------------------ //
  // ------------------------------------------------------ //

  const executeScroll = () => titleRef.current.scrollIntoView({ behavior: "smooth" })

  function getTimeZones() {
    const timezones = TimezoneList;
    const options = timezones.map((timeZone) => {
      return (
        <DropdownOption label={timeZone} value={timeZone} key={timeZone} />
      )
    })
    return options;
  }

  const timezoneDropdownOptions = getTimeZones();

  const countryDropdownOptions = countryList && countryList.map((country) => {
    return (
      <DropdownOption label={country.name} value={country.id} key={country.name + "_" + country.id} />
    )
  })

  function NoneSelectedActivities() {
    return activities_cex_bought_sold
      || activities_contracts_created
      || activities_personal_wallet
      || activities_nfts_bought_sold
      || activities_nfts_created
      || activities_defi_used
      || activities_crypto_sent
      || activities_crypto_bridged
      || activities_crypto_staked
      || activities_none
  }

  function NoneSelectedUserTypes() {
    return holder
      || creatorArtContent
      || socialMediaUser
      || nftTrader
      || memecoinTrader
      || occasionalDefiTrader
      || heavyDefiTrader
      || onChainGamer
      || daoParticipant
      || developer
      || newToBlockchain
      || noneOfTheAbove;
  }

  function SelectNoneForActivities(booleanValue) {
    if (booleanValue) {
      setActivities_cex_bought_sold(false)
      setActivities_contracts_created(false)
      setActivities_personal_wallet(false)
      setActivities_nfts_bought_sold(false)
      setActivities_nfts_created(false)
      setActivities_defi_used(false)
      setActivities_crypto_sent(false)
      setActivities_crypto_bridged(false)
      setActivities_crypto_staked(false)
      setActivities_none(true)
    } else {
      setActivities_none(false)
    }
  }

  function SelecteNoneForUserType(booleanValue) {
    if (booleanValue) {
      setHolder(false)
      setCreatorArtContent(false)
      setSocialMediaUser(false)
      setNftTrader(false)
      setMemecoinTrader(false)
      setOccasionalDefiTrader(false)
      setHeavyDefiTrader(false)
      setOnChainGamer(false)
      setDaoParticipant(false)
      setDeveloper(false)
      setNewToBlockchain(false)
      setNoneOfTheAbove(true)
    } else {
      setNoneOfTheAbove(false)
    }
  }

  function updateChecked(setter, noneSetter, boolean) {
    if (boolean) {
      noneSetter(false)
    }
    setter(boolean)
  }

  const walletPageIndex = 4
  const countryAndNamePageIndex = showUserWalletQuestions ? 5 : 4

  // Returns the actual slides with questions
  function Slides(props) {

    const walletSlide = (showUserWalletQuestions ?
      <Slide key={walletPageIndex} >
        <MultipleChoiceQuestion questionText="Do you have an Ethereum or EVM wallet?">
          <RadioOption selectMultiple={false} onChange={setHasWallet} label="Yes" name="has_evm" id="wallet_yes" value="true" />
          <RadioOption selectMultiple={false} onChange={setHasWallet} label="No" name="has_evm" id="wallet_no" value="false" />
        </MultipleChoiceQuestion>
        {(hasWallet == "true") && <TextEntryQuestion label="What is your Ethereum wallet address? This is optional, but will help match more missions." name="wallet" id="wallet" placeholder="EVM Wallet Address" type="text" autoComplete="off" onChange={setWalletAddress} />}
      </Slide>
      :
      null
    )

    return (
      <>
        <SlideContainer handleSubmit={handleSubmit} highlightPage={highlightPage} executeScroll={executeScroll}>
          <Slide key={0} >
            <div>
              Welcome to despark! First, please answer a few questions about your crypto experiences. This will help us match you to feedback missions.
            </div>
            <div className="share-info-note mt-4">
              <div >
                Questions about how we use your information? Learn more <a className="gray-link" href="https://desparkwiki.notion.site/How-does-Despark-protect-my-information-b0afbb4aafee494e8d4a6a974c41a65c" target="_blank" > here</a>.
              </div>
            </div>

          </Slide>
          <Slide key={1} >
            <MultipleChoiceQuestion questionText="Which of the following activities have you done?">
              <CheckboxOption label="Bought crypto/tokens on a central exchange (e.g. Coinbase, Binance, Kraken, etc.)" id="activities_cex_bought_sold" value="activities_cex_bought_sold" checked={activities_cex_bought_sold} onChange={(boolean) => updateChecked(setActivities_cex_bought_sold, setActivities_none, boolean)} />
              <CheckboxOption label="Used a personal wallet/address (e.g. Metamask, Trust Wallet, etc.)" id="activities_personal_wallet" value="activities_personal_wallet" checked={activities_personal_wallet} onChange={(boolean) => updateChecked(setActivities_personal_wallet, setActivities_none, boolean)} />
              <CheckboxOption label="Purchased or sold NFTs" id="activities_nfts_bought_sold" value="activities_nfts_bought_sold" checked={activities_nfts_bought_sold} onChange={(boolean) => updateChecked(setActivities_nfts_bought_sold, setActivities_none, boolean)} />
              <CheckboxOption label="Created NFTs (you made a smart contract with NFTs)" id="activities_nfts_created" value="activities_nfts_created" checked={activities_nfts_created} onChange={(boolean) => updateChecked(setActivities_nfts_created, setActivities_none, boolean)} />
              <CheckboxOption label="Used DeFi Contracts" id="activities_defi_used" value="activities_defi_used" checked={activities_defi_used} onChange={(boolean) => updateChecked(setActivities_defi_used, setActivities_none, boolean)} />
              <CheckboxOption label="Sent crypto/tokens to another wallet" id="activities_crypto_sent" value="activities_crypto_sent" checked={activities_crypto_sent} onChange={(boolean) => updateChecked(setActivities_crypto_sent, setActivities_none, boolean)} />
              <CheckboxOption label="Bridged crypto/tokens across blockchains" id="activities_crypto_bridged" value="activities_crypto_bridged" checked={activities_crypto_bridged} onChange={(boolean) => updateChecked(setActivities_crypto_bridged, setActivities_none, boolean)} />
              <CheckboxOption label="Staked tokens" id="activities_crypto_staked" value="activities_crypto_staked" checked={activities_crypto_staked} onChange={(boolean) => updateChecked(setActivities_crypto_staked, setActivities_none, boolean)} />
              <CheckboxOption label="Created smart contracts (for anything other than NFTs)" id="activities_contracts_created" value="activities_contracts_created" checked={activities_contracts_created} onChange={(boolean) => updateChecked(setActivities_contracts_created, setActivities_none, boolean)} />
              <CheckboxOption label="None of the above" id="activities_none" value="activities_none" checked={activities_none} onChange={SelectNoneForActivities} />
            </MultipleChoiceQuestion>
          </Slide>
          <Slide key={2} >
            <MultipleChoiceQuestion questionText="How interested are you in cryptocurrencies and web3?">
              <RadioOption onChange={setInterest} label="1 - Not at all interested" name="interest" id="interested_1" value="1" />
              <RadioOption onChange={setInterest} label="2" name="interest" id="interested_2" value="2" />
              <RadioOption onChange={setInterest} label="3" name="interest" id="interested_3" value="3" />
              <RadioOption onChange={setInterest} label="4" name="interest" id="interested_4" value="4" />
              <RadioOption onChange={setInterest} label="5 - Very interested" name="interest" id="interested_5" value="5" />
            </MultipleChoiceQuestion>
            <MultipleChoiceQuestion questionText="How familiar are you with cryptocurrencies and web3?">
              <RadioOption onChange={setFamiliar} label="1 - Not at all familiar" name="familiar" id="familiar_1" value="1" />
              <RadioOption onChange={setFamiliar} label="2" name="familiar" id="familiar_2" value="2" />
              <RadioOption onChange={setFamiliar} label="3" name="familiar" id="familiar_3" value="3" />
              <RadioOption onChange={setFamiliar} label="4" name="familiar" id="familiar_$" value="4" />
              <RadioOption onChange={setFamiliar} label="5 - Very familiar" name="familiar" id="familiar_5" value="5" />
            </MultipleChoiceQuestion>
          </Slide>
          <Slide key={3} >
            <MultipleChoiceQuestion questionText="Which of the following user types do you consider yourself? Please select all that apply.">
              <CheckboxOption label={userTypeList["holder"]} id="holder" value="holder" checked={holder} onChange={(boolean) => updateChecked(setHolder, setNoneOfTheAbove, boolean)} />
              <CheckboxOption label={userTypeList["creator_art_content"]} id="creator_art_content" value="creator_art_content" checked={creatorArtContent} onChange={(boolean) => updateChecked(setCreatorArtContent, setNoneOfTheAbove, boolean)} />
              <CheckboxOption label={userTypeList["social_media_user"]} id="social_media_user" value="social_media_user" checked={socialMediaUser} onChange={(boolean) => updateChecked(setSocialMediaUser, setNoneOfTheAbove, boolean)} />
              <CheckboxOption label={userTypeList["nft_trader"]} id="nft_trader" value="nft_trader" checked={nftTrader} onChange={(boolean) => updateChecked(setNftTrader, setNoneOfTheAbove, boolean)} />
              <CheckboxOption label={userTypeList["memecoin_trader"]} id="memecoin_trader" value="memecoin_trader" checked={memecoinTrader} onChange={(boolean) => updateChecked(setMemecoinTrader, setNoneOfTheAbove, boolean)} />
              <CheckboxOption label={userTypeList["occasional_defi_trader"]} id="occasional_defi_trader" value="occasional_defi_trader" checked={occasionalDefiTrader} onChange={(boolean) => updateChecked(setOccasionalDefiTrader, setNoneOfTheAbove, boolean)} />
              <CheckboxOption label={userTypeList["heavy_defi_trader"]} id="heavy_defi_trader" value="heavy_defi_trader" checked={heavyDefiTrader} onChange={(boolean) => updateChecked(setHeavyDefiTrader, setNoneOfTheAbove, boolean)} />
              <CheckboxOption label={userTypeList["on_chain_gamer"]} id="on_chain_gamer" value="on_chain_gamer" checked={onChainGamer} onChange={(boolean) => updateChecked(setOnChainGamer, setNoneOfTheAbove, boolean)} />
              <CheckboxOption label={userTypeList["dao_participant"]} id="dao_participant" value="dao_participant" checked={daoParticipant} onChange={(boolean) => updateChecked(setDaoParticipant, setNoneOfTheAbove, boolean)} />
              <CheckboxOption label={userTypeList["developer"]} id="developer" value="developer" checked={developer} onChange={(boolean) => updateChecked(setDeveloper, setNoneOfTheAbove, boolean)} />
              <CheckboxOption label={userTypeList["new_to_blockchain"]} id="new_to_blockchain" value="new_to_blockchain" checked={newToBlockchain} onChange={(boolean) => updateChecked(setNewToBlockchain, setNoneOfTheAbove, boolean)} />
              <CheckboxOption label={userTypeList["none_of_the_above"]} id="none_of_the_above" value="none_of_the_above" checked={noneOfTheAbove} onChange={SelecteNoneForUserType} />
            </MultipleChoiceQuestion>
          </Slide>
          {walletSlide}
          <Slide key={countryAndNamePageIndex} >
            <DropdownQuestion questionText="What country do you live in?" name="country" id="country" onChange={setCountry} placeholder="Select country" defaultValue="-1" marginClass="mb-4">
              {/* <DropdownOption label="United States" value="1" /> */}
              {countryDropdownOptions}
            </DropdownQuestion>
            <DropdownQuestion questionText="What is your timezone?" name="timezone" id="select_timezone" onChange={setTimezone} defaultValue={Intl.DateTimeFormat().resolvedOptions().timeZone} marginClass="mb-4">
              {timezoneDropdownOptions}
            </DropdownQuestion>
            {/* Dropdown - Timezone - Preselect */}
            <TextEntryQuestion label="How should we address you? This can be your real name or a pseudonym" name="first_name" id="first_name" placeholder="First Name or Pseudonym" type="text" autoComplete="off" onChange={setFirstName} />
          </Slide>
        </SlideContainer>
      </>
    )
  }


  // ------------------------------------------------------ //
  // ------------------------------------------------------ //
  // ------------------- Return Object--------------------- //
  // ------------------------------------------------------ //
  // ------------------------------------------------------ //

  return (
    <div className="container-fluid bg-black-color">
      <div ref={titleRef} className='row d-flex justify-content-center align-items-center h-100'>
        <ErrorMessage errors={error} />
        <NoticeMessage notices={notice} />
        <div className="bg-black-color full-page-background">
        </div>
        <div className="col12">
          <div className=' mw-708 px-2 px-sm-4  my-80px mx-auto'>  {/* This sets the width and margins */}
            <div className='mx-auto'>  {/* This sets the width and margins */}
              <LogInCard align="start">
                <h3 className="signup-title-font mb-4">Profile Questions</h3>
                <div className="card-body-2 px-2">
                  <form onSubmit={handleSubmit} className="form bg-transparent-color no-outline text-white w-100">
                    {Slides()}
                  </form>
                </div>
              </LogInCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}




export default SignUpQuestions