import React, { useEffect, useState, useContext } from "react";
import { useAlert } from 'react-alert';
import CardAccount from "../components/dashboard_components/CardAccount";
import CardExtendedProfile from "../components/dashboard_components/CardExtendedProfile";
import { UserContext } from "../../UserContext";

import { UserPortalContainer, AccountColumn } from "../components/LayoutComponents";
import HeaderUser from "../components/menu_components/HeaderUser";

// API Calls
import UserDemographicAPI from "../api/api_calls/UserDemographicAPI";


function ExtendedProfile(props) {
  const { user, setUser, refreshUser } = useContext(UserContext);

  const alert = useAlert();
  const [age, setAge] = useState([]);
  const [education, setEducation] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [income, setIncome] = useState([]);
  const [gender, setGender] = useState([]);
  const [loadExtendedComplete, setLoadExtendedComplete] = useState(false);

  useEffect(() => {
    // Grab extended profile info on this user
    const extendedProfileData = async () => {
      const data = await UserDemographicAPI.show();

      if (data.success) {

        setAge(data.age);
        setEducation(data.education_id);
        setIndustry(data.industry_id);
        setJobTitle(data.job_title);
        setIncome(data.income_id);
        setGender(data.gender_id);
        setLoadExtendedComplete(true);
      } else {
        alert.show("Unable to load extended profile info. Try again later", { type: 'error' })
      }
    }

    extendedProfileData();
  }, []);


  return (
    <>
      <HeaderUser />
      <UserPortalContainer>
        <AccountColumn />
        <div className="col px-2">
          <CardExtendedProfile age={age} education={education}
            industry={industry} jobTitle={jobTitle}
            income={income} gender={gender}
            loadExtendedComplete={loadExtendedComplete}
          />
        </div>
      </UserPortalContainer>
    </>

  )
}


export default ExtendedProfile;