import React, { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { ConnectButton } from '@rainbow-me/rainbowkit';



function HeaderPublic(props) {
    //Use effect that sets body, html background color to #0c0c0c

    useEffect(() => {
        document.body.style.backgroundColor = "#0c0c0c";
    }, []);

    const toRoute = (props.logoRoute == "homepage") ? "https://despark.io" : "/signin";

    return (
        <>
            <header>
                <div className="container-fluid p-0 ">
                    <div className="navbar navbar-new navbar-dark bg-black-color bottom-outline-gray px-3 px-32-sm">
                        <div className="navbar-brand my-3">
                            <strong className="logo-text-new">
                                de<span className="logo-span-highlight-new">spark</span>
                            </strong>
                        </div>
                        <div className="">
                            <ConnectButton showBalance={false} chainStatus="none" />
                        </div>
                    </div>
                </div>
            </header>
            <Outlet />
        </>
    )

}

export default HeaderPublic