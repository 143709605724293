import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from 'react-alert';

import { MissionDetailsHighlight, VertSpacer } from "../../overview_components/CommonOverviewComponents";
import { SecondaryButtonSmall } from "../../../preauth_portal/components/CommonComponents";
import LoadingIcon from "../../standard_components/LoadingIcon";

// API Calls
import CashOutAPI from "../../api/api_calls/CashOutAPI";


function PublicCashOutTable(props) {
    let params = useParams()
    const missionId = params.missionId;
    const navigate = useNavigate();
    const alert = useAlert();


    // ----------------------------------------
    // ----------------------------------------
    // --------------- UseEffects ----------------
    // ----------------------------------------
    // ----------------------------------------




    // ----------------------------------------
    // ----------------------------------------
    // --------------- Helpers ----------------
    // ----------------------------------------
    // ----------------------------------------

    // Delete the cash out and send to create a new one
    function onSubmit(cashOutId) {

        async function CashOutRequest() {
            const returnData = await CashOutAPI.deleteCashOut(missionId, cashOutId, navigate)

            if (returnData.success) {
                props.setRefreshCashOuts(props.refreshCashOuts + 1)
                alert.show("Request deleted", { type: "success" })
                // Can't do state here because of the Rails backend. 
            } else {
                alert.show(returnData.error.message, { type: 'error' })
            }
        }

        CashOutRequest();
    }

    function StatusLink({ cashOut }) {
        if (cashOut.status == "completed") {
            return (
                <a href={cashOut.transaction_link}> Completed - Link </a>
            )
        } else {
            return (
                cashOut.status
            )
        }
    }

    function CashOutRows() {
        const rows = props.cashOutList.map((cashOut) => {
            return (
                <tr key={"CashOut" + cashOut.id}>
                    <td> {cashOut.eth_address} </td>
                    <td> {cashOut.blockchain} </td>
                    <td> ${cashOut.usd_amount_formatted} </td>
                    <td> <StatusLink cashOut={cashOut} /> </td>
                    <td> {cashOut.status == "requested" ?
                        (<SecondaryButtonSmall type="button" value={cashOut.id} onClick={() => onSubmit(cashOut.id)} text="Delete & Update" />)
                        :
                        <></>
                    }
                    </td>
                </tr>
            )
        })

        return (
            <tbody>
                {rows}
            </tbody>
        )
    }

    function CashOutHeader() {
        return (
            <thead>
                <tr>
                    <th> Address </th>
                    <th> Chain </th>
                    <th> Amount </th>
                    <th> Status </th>
                    <th> Edit </th>
                </tr>
            </thead>
        )

    }

    function CashOutTableHelper() {
        return (
            <div>
                <div className="table-responsive table-public-container">
                    <table className="table table-public mb-2">
                        <CashOutHeader />
                        <CashOutRows />
                    </table>
                </div>
                <div className="minor-table-note">
                    Cash outs are generally processed within 48 hours.
                </div>
            </div>
        )
    }


    // ADD IN THE LINK FOR COMPLETED ONES
    return (
        <>
            <h5 className="my-0"> Cash Out Status</h5>
            {props.cashOutLoaded ? <CashOutTableHelper /> : <LoadingIcon />}
        </>
    )

}


export default PublicCashOutTable;