import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {
  // Get the signature message based on this wallet
  getMission: async (mission_id, handleRedirect = null) => {
    const url = apiConfig.apiUrl + mission_id;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: addCommonHeaders(),
        credentials: 'include',
      })

      return await handleResponse(response, handleRedirect);

    } catch (error) {
      const returnData = handleErrors(error);
      returnData["mission"] = null
      return returnData
    }
  },

  // Check whether the wallet meets the mission requirements
  checkWalletRequirements: async (wallet_address, mission_id, handleRedirect) => {
    const url = apiConfig.apiUrl + mission_id + "/check_wallet_requirements";


    const body = JSON.stringify({
      wallet_address: wallet_address
    })

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: addCommonHeaders(),
        body: body,
        credentials: 'include',
      })

      return await handleResponse(response, handleRedirect);

    } catch (error) {
      return handleErrors(error);
    }
  }


}

