import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from 'react-alert';
import { FormField, PrimaryButton, LogInCard, ErrorMessage, NoticeMessage, PreAuthPageContainer, TurnBackgroundBlackComponent, LogInContainer, LogInCardTitle, LogInCardBody, LogInForm, SignUpTertiary } from "../components/CommonComponents"

// API Calls
import PasswordAPI from "../api/api_calls/PasswordAPI";



/*sync function loginUser(credentials) {
    const token = document.querySelector('meta[name="csrf-token"]').content;

    return fetch('http://localhost:3000/login', {
      method: 'POST',
      headers: {
        "X-CSRF-Token": token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
}*/

function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [notice, setNotice] = useState("");




  function onChange(event) {
    switch (event.target.name) {
      case "email":
        setEmail(event.target.value);
    }
  }



  function onSubmit(event) {
    event.preventDefault();

    if (email.length == 0) { return; }

    // You must do it this way, with the user on the outside. 
    const body = JSON.stringify({
      user: {
        email: email
      }
    });

    const forgotPassword = async () => {
      const data = await PasswordAPI.create(email);

      if (data.success) {
        setNotice("If that email is in our system, you will receive an email with instructions on how to reset your password.")
        setError("")
      } else {
        // sets the message to display to user
        setError(data.failureReason);
        setNotice("")
      }

    }

    forgotPassword();
  }


  return (
    <PreAuthPageContainer>

      <ErrorMessage errors={error} />
      <NoticeMessage notices={notice} />

      <TurnBackgroundBlackComponent />
      <LogInContainer >

        <LogInCard>
          <LogInCardTitle title="Forgot Password" />
          <LogInCardBody >

            <LogInForm onSubmit={(event) => onSubmit(event)} >

              <FormField label="Email" name="email" id="userEmail" placeholder="Email" type="email" autoComplete="username" onChange={onChange} marginClass="mb-4" />
              <div className="height-40">
              </div>
              <div className="mt-32">
                <PrimaryButton text="Reset Password" buttonType="submit" marginClass="mb-3" />
              </div>
            </LogInForm>

          </LogInCardBody>

        </LogInCard>
        <SignUpTertiary preLinkText="Remember your password? " linkText="Login" linkPath="/signin" />

      </LogInContainer>

    </PreAuthPageContainer>


  );

}

// async function ForgotPasswordApiCall(body) {
//   const url = "/users/password";
//   const token = document.querySelector('meta[name="csrf-token"]').content;

//   try {
//     const response = await fetch(url, {
//       method: "POST",
//       headers: {
//         "X-CSRF-Token": token,
//         "Content-Type": "application/json"
//       },
//       body: body
//     })

//     console.log(response)

//     if (response.ok) {
//       const responseBody = await response.json();
//       const userEmail = responseBody.data.email
//       const returnData = {
//         success: true,
//         failureReason: "",
//         userEmail: userEmail
//       }

//       return returnData
//     } else if (response.status == 401) {
//       const returnData = {
//         success: false,
//         failureReason: "The email or password you entered is incorrect.",
//         email: ""
//       }
//       return returnData
//     } else {
//       console.log("Damn")
//       throw new Error("Network response was not ok.");
//     }

//   } catch (error) {
//     const returnData = {
//       success: false,
//       failureReason: "Unable to complete request. Check your login and try again later",
//       email: ""
//     }
//     return returnData
//   }
// }




export default ForgotPassword;