import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';

// API Calls
import MissionEventAvailabilityAPI from "../../api/api_calls/MissionEventAvailabilityAPI";


function AppointmentSubmitButton(props) {
    const navigate = useNavigate();
    const alert = useAlert();

    const [submitting, setSubmitting] = useState(false);


    function onClick() {
        async function submitAppointment() {
            const response = await MissionEventAvailabilityAPI.claim(props.selectedAppointmentId, navigate)

            if (response.success) {
                navigate(0);
            } else {
                console.log("Network response was not ok.")
                setSubmitting(false)
                if (response.error) {
                    alert.show(response.error, { type: 'error' })
                } else {
                    alert.show("Unable to submit. Try again later", { type: 'error' })
                }
            }
        }

        if (submitting) return;
        setSubmitting(true)
        submitAppointment();
    }


    const activeClassString = "btn btn-md btn-primary-color btn-shadow text-white no-outline no-text-wrap px-3 my-3"
    const disabledClassString = "btn btn-gray btn-disabled no-text-wrap px-3 my-3 disabled"
    let classString = props.selectedAppointmentId ? (submitting ? disabledClassString : activeClassString) : disabledClassString

    return (
        <div className={classString} onClick={onClick}>
            {submitting ? "Submitting..." : "Submit Appointment"}
        </div>
    )
}



// async function SubmitAppointmentApiCall(missionEventAvailabilityId, navigate, alert, setSubmitting) {
//     const url = "/api/v1/missioneventavailabilities/" + missionEventAvailabilityId;
//     const token = document.querySelector('meta[name="csrf-token"]').content;

//     setSubmitting(true)

//     const body = JSON.stringify({
//         mission_event_availability: {
//             missionEventAvailabilityId
//         }
//     });

//     try {
//         const response = await fetch(url, {
//             method: "PATCH",
//             headers: {
//                 "X-CSRF-Token": token,
//                 "Content-Type": "application/json"
//             },
//             body: body
//         })
//         if (response.ok) {
//             const body = await response.json();

//             // Refresh the page
//             navigate(0);

//             return body
//         } else {
//             setSubmitting(false)
//             alert.show("Unable to submit. Try again later", { type: 'error' })
//             throw new Error("Network response was not ok.");
//         }
//     } catch (error) {
//         console.log(error.message)
//         setSubmitting(false)
//         return { error: true }
//     }
// }

export default AppointmentSubmitButton; 