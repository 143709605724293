import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {
    claim: async (missionEventAvailabilityId, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "missioneventavailabilities/" + missionEventAvailabilityId;

        try {
            const response = await fetch(url, {
                method: "PATCH",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    index: async (missionId, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "missions/" + missionId + "/availableappointments";

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },



}