import React, { useEffect, useState, useContext } from "react";
import CardAccount from "../components/dashboard_components/CardAccount";
import MissionsListCard from "../components/missions_components/MissionsListCard";
import { UserContext } from "../../UserContext";
import { UserPortalContainer, AccountColumn } from "../components/LayoutComponents";
import HeaderUser from "../components/menu_components/HeaderUser";






function ExploreMissions(props) {
  const { user, setUser, refreshUser } = useContext(UserContext);

  return (
    <>
      <HeaderUser />
      <UserPortalContainer>
        <AccountColumn />
        <div className="col px-2">
          <MissionsListCard extendedProfileCompleteBool={user.extended_profile_complete} />

        </div>
      </UserPortalContainer>
    </>
  )
}


export default ExploreMissions