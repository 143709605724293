import React from "react";
import { render } from "react-dom";
import AppWrapper from "../components/AppWrapper";

// Redirect from hashrouter style to browser router style
if (window.location.hash && window.location.hash.startsWith('#/')) {
  const newPath = window.location.pathname + window.location.hash.substring(2); // remove the "#/"
  window.location.replace(newPath);
}


document.addEventListener("DOMContentLoaded", () => {
  render(
    <AppWrapper />,
    document.body.appendChild(document.createElement("div"))
  )
}, { once: true });


