import React from "react";
import { VertSpacer, MissionDetailsHighlight } from "../../overview_components/CommonOverviewComponents";
import { PrimaryButtonSmall } from "../../../preauth_portal/components/CommonComponents";


function PublicSchedulePlaceholder(props) {


    function BackButton() {
        if (props.rescheduleClicked) {
            return (
                <div className="row">
                    <div className="col">
                        <PrimaryButtonSmall type="button" text="Back" onClick={() => props.cancelReschedule()} />
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    return (
        <>
            <MissionDetailsHighlight>
                There are no currently available times. We're working to find new time slots for sessions. Please check back again soon.
            </MissionDetailsHighlight>
            <VertSpacer />
            <BackButton />
            <VertSpacer />



        </>)
}

export default PublicSchedulePlaceholder