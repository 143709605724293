import React, { useState, useEffect, useCallback, useReducer } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useAlert } from 'react-alert';
import { Survey, Model, StylesManager } from "survey-react";
import 'survey-react/defaultV2.min.css';

// API Calls
import SurveyAPI from "../../api/api_calls/SurveyAPI";




function ScreenerContent(props) {
    const alert = useAlert();
    const [screener, setScreener] = useState(null);
    const [screenerComplete, setScreenerComplete] = useState(false);
    const [screenerTitle, setScreenerTitle] = useState("Loading...");
    const [missionTitle, setMissionTitle] = useState("Loading...");
    const [missionReward, setMissionReward] = useState("Loading...");
    const [failedLoading, setFailedLoading] = useState(false);
    const [screenerFailed, setScreenerFailed] = useState(false);

    const [screenerLoaded, setScreenerLoaded] = useState(false);
    const exampleProp = useOutletContext();
    let params = useParams()
    const screenerId = params.screenerId;
    const missionId = params.missionId;

    StylesManager.applyTheme("defaultV2");

    const navigate = useNavigate();



    // On complete, save the screener results
    const onScreenerComplete = useCallback(async (sender) => {
        // This is for the admin survey display. 
        // Stops from actually submitting the results. 
        if (props.adminResponse) {
            alert.show("Admin does not submit surveys", { type: "info" });
        } else {

            const isScreener = true
            const response = await SurveyAPI.create_response(sender.data, screenerId, isScreener, navigate)

            setScreenerComplete(true);

            if (response.success) {
                if (response.next == "missions") {
                    // Set it to failed, and let user go back to missions themself
                    setScreenerFailed(true);
                } else if (response.next == "review") {
                    navigate("/mission/" + response.mission_id + "/review/")
                } else if (response.next == "schedule") {
                    navigate("/mission/" + response.mission_id + "/schedule/")
                } else if (response.next == "survey") {
                    navigate("/mission/" + response.mission_id + "/surveys/" + response.survey_id)
                } else if (response.next == "autonomous") {
                    navigate("/mission/" + response.mission_id + "/exploration/")
                }
            } else {
                alert.show("Unable to save survey. Please contact info@despark.io", { type: 'error' })
            }
        }

    }, []);

    // Load the screener
    useEffect(() => {
        //Grab information on this user
        const GetScreener = async () => {
            const response = await SurveyAPI.show(screenerId);

            if (response.success) {
                // Once it loads, then run through and set all the screener stuff
                const tempScreener = new Model(response.survey);
                tempScreener.onComplete.add(onScreenerComplete);
                setScreener(tempScreener);
                setScreenerLoaded(true);
                setScreenerTitle(response.title);
                setMissionTitle(response.mission_title);
                setMissionReward(response.mission_reward);
            } else {
                setFailedLoading(true);
            }

        }

        GetScreener();
    }, []);


    return (
        <div className="row mx-4 text-white">
            <div className="surveytitle px-4 py-4">
                {screenerLoaded || failedLoading ? missionTitle : <div>Loading...</div>}
                <div className="normal-font my-3">
                    This mission requires users to complete a brief screener before starting the mission. Please answer the short list of questions below.
                </div>
            </div>
            <SwitchCompleteAndFailedScreener failedLoading={failedLoading} screenerComplete={screenerComplete} screenerLoaded={screenerLoaded} screener={screener} screenerFailed={screenerFailed} />
        </div>
    )

}

function SwitchCompleteAndFailedScreener(props) {
    if (props.failedLoading) {
        return (
            <div className="surveyComplete d-flex flex-column align-items-center justify-content-center my-4 py-5">
                <div className="pt-4">
                    Screener not available. Have you already taken this screener?
                </div>
                <Link to={{ pathname: "/missions" }}
                    className="btn btn-md btn-primary-color btn-shadow text-white no-outline px-3 my-3">
                    Back to Missions
                </Link>
            </div>
        )
    } else if (props.screenerFailed) {
        return (
            <div className="surveyComplete d-flex flex-column align-items-center justify-content-center my-4 py-5">
                <div className="pt-4">
                    Unfortunately, you aren't the target audience for this mission.  Please try another mission.
                </div>
                <Link to={{ pathname: "/missions" }}
                    className="btn btn-md btn-primary-color btn-shadow text-white no-outline px-3 my-3">
                    Back to Missions
                </Link>
            </div>
        )
    } else if (props.screenerComplete) {
        return (
            <div className="surveyComplete d-flex flex-column align-items-center justify-content-center my-4 py-5">
                <div className="pt-4">
                    Thank you for completing the screener. Saving your responses...
                </div>
            </div>
        )
    } else {
        return (
            <div id="surveyElement">
                {props.screenerLoaded ? <Survey model={props.screener} /> : <div>Loading...</div>}
            </div>
        )
    }
}

// // This function is called when the screener is completed and sends the results to the server
// function saveScreenerResults(url, json, setComplete, setFailed, navigate) {
//     //const navigate = useNavigate();

//     // Keeping it as survey to keep closer to survey hadnling. 
//     const body = JSON.stringify({ survey_response: json })
//     const token = document.querySelector('meta[name="csrf-token"]').content;

//     setComplete(true);

//     // Need to update this to have the "push" function go to user's homepage
//     fetch(url, {
//         method: "POST",
//         headers: {
//             "X-CSRF-Token": token,
//             "Content-Type": "application/json"
//         },
//         credentials: "include",
//         body: body
//     })
//         .then(response => {
//             if (response.ok) {
//                 return response.json();
//             }
//             throw new Error("Network response was not ok.");
//             console.log(body)
//         })
//         .then(response => {

//             // PAss:  message: "Thanks for your submission!", next: "schedule"}
//             // Fail:  message: "Unfortunately, you're not the target audience", next: "missions"}

//             if (response.next == "missions") {
//                 // Set it to failed, and let user go back to missions themself
//                 setFailed(true);
//             } else if (response.next == "review") {
//                 navigate("/review/" + response.mission_id)
//             } else if (response.next == "schedule") {
//                 navigate("/schedule/" + response.mission_id)
//             } else if (response.next == "survey") {
//                 navigate("/surveys/" + response.survey_id)
//             } else if (response.next == "autonomous") {
//                 navigate("/exploration/" + response.mission_id)
//             }
//             return response
//         })
//         .catch(error => console.log(error.message));
// }

// // This function is called when the screener is loaded and grabs the screener from the server
// async function ScreenerApiCall(screenerId) {
//     const url = "/api/v1/surveys/" + screenerId;
//     const token = document.querySelector('meta[name="csrf-token"]').content;

//     try {
//         const response = await fetch(url, {
//             method: "GET",
//             headers: {
//                 "X-CSRF-Token": token,
//                 "Content-Type": "application/json"
//             }
//         })


//         if (response.ok) {
//             const body = await response.json();
//             return body

//         } else if (response.status == 403) {
//             const body = await response.json()
//             console.log("Got here")
//             return body

//         } else {
//             throw new Error("Network response was not ok.");
//         }
//     } catch (error) {
//         console.log(error.message)
//         return { error: true }
//     }
// }

export default ScreenerContent