import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAlert } from 'react-alert';
import { MissionDetailsHighlight, VertSpacer } from "../../overview_components/CommonOverviewComponents";
import PublicSignUpCard from "./PublicSignUpCard";
import PublicCashOutTable from "./PublicCashOutTable";
import PublicCashOut from "./PublicCashOut";
import LoadingIcon from "../../standard_components/LoadingIcon";

// API Calls
import CashOutAPI from "../../api/api_calls/CashOutAPI";

function PublicComplete(props) {
    const [completionNotice, setCompletionNotice] = useState("Thank you for participating!");
    const [cashOutList, setCashOutList] = useState([])
    const [cashOutLoaded, setCashOutLoaded] = useState(false)
    const [cashOutCount, setCashOutCount] = useState(0)
    const [refreshCashOuts, setRefreshCashOuts] = useState(0)

    const location = useLocation();
    const navigate = useNavigate();
    let params = useParams()
    const missionId = params.missionId;
    const alert = useAlert();


    // ----------------------------------------
    // ----------------------------------------
    // --------------- UseEffects ----------------
    // ----------------------------------------
    // ----------------------------------------

    useEffect(() => {
        if (location.state) {
            if (location.state.receivedReward) {
                setCompletionNotice("Thank you for participating! Your reward will be sent within 48 hours")
            }
        }



        // Get the list of current cashouts
        async function GetCashOuts() {
            const cashOutData = await CashOutAPI.getCashOuts(missionId, navigate)
            if (cashOutData.success) {
                setCashOutList(cashOutData.cash_outs)
                setCashOutCount(cashOutData.cash_outs.length)
                setCashOutLoaded(true)
            }
        }

        GetCashOuts()
    }, [refreshCashOuts]);

    // ----------------------------------------
    // ----------------------------------------
    // --------------- Helpers ----------------
    // ----------------------------------------
    // ----------------------------------------

    function CashOutSection() {
        if (props.hasReward) {
            if (cashOutLoaded) {
                if (cashOutCount > 0) {
                    // If there are cashouts, show the table
                    return (
                        <>
                            <PublicCashOutTable cashOutList={cashOutList} cashOutLoaded={cashOutLoaded} setRefreshCashOuts={setRefreshCashOuts} refreshCashOuts={refreshCashOuts} />
                            <VertSpacer />
                            <PublicSignUpCard />
                        </>
                    )
                } else {
                    // If there are no cashouts, show the cashout form
                    return (
                        <>
                            <PublicCashOut setRefreshCashOuts={setRefreshCashOuts} refreshCashOuts={refreshCashOuts} />
                        </>
                    )
                }
            } else {
                // If the cashouts are still loading, show a loading message
                return (
                    <>
                        <LoadingIcon />
                    </>
                )
            }
        } else {
            // If the mission has no reward, show the sign up card
            return (
                <>
                    <PublicSignUpCard />
                </>
            )
        }
    }


    return (
        <>
            <MissionDetailsHighlight>
                {completionNotice}
            </MissionDetailsHighlight>
            <VertSpacer />
            <CashOutSection />

        </>
    )

}

export default PublicComplete;