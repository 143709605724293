import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Placeholder from "../menu_components/Placeholder";
import { useAlert } from "react-alert";

// API Calls
import CashOutAPI from "../../api/api_calls/CashOutAPI";


function CashOutHistory(props) {
    const navigate = useNavigate();
    const alert = useAlert();

    const cashOutHistory = props.cashOutHistory;
    const cashOutHistoryList = cashOutHistory.map((cashOut, index) => (
        <tr key={index}>
            <td className="mw-200">{(new Date(cashOut.created_at)).toLocaleDateString('en-us', { year: "numeric", month: "long", day: "numeric" })}</td>
            <td className="mw-200">{cashOut.status}</td>
            <td className="mw-200">${cashOut.usd_amount}</td>
            <td className="mw-200">{cashOut.blockchain}</td>
            <td className="mw-200">{cashOut.eth_address}</td>
            <td className="mw-200">{
                cashOut.status == "completed" ?
                    cashOut.blockchain == "Ethereum" ?
                        <a href={"https://etherscan.io/tx/" + cashOut.tx_hash} target="_blank" rel="noreferrer" className="text-link">View</a> :
                        <a href={"https://polygonscan.com/tx/" + cashOut.tx_hash} target="_blank" rel="noreferrer" className="text-link">View</a> :
                    "N/A"
            }</td>
            <td className="mw-200">{cashOut.status == "requested" ? <CashOutDeleteButton cashOutId={cashOut.id} navigate={navigate} alert={alert} /> : ""}</td>
        </tr>
    ));

    const emptyCard = (
        <div className="card-body no-outline profile-style d-flex full-height align-items-center justify-content-center px-1 py-5">
            <div className="">
                No existing cash out requests
            </div>
        </div>
    )

    const fullCard = (
        <div className="table-wrapper table-responsive rounded-corners mx-4 mt-4 mb-5">
            <table className="table table-sm table-secondary-gray table-hover white-text">
                <thead>
                    <tr>
                        <th scope="col" className="mw-200">Date Created</th>
                        <th scope="col" className="mw-200">Status</th>
                        <th scope="col" className="mw-200">Amount</th>
                        <th scope="col" className="mw-200">Blockchain</th>
                        <th scope="col" className="mw-200">Wallet Address</th>
                        <th scope="col" className="mw-200">Transaction Link</th>
                        <th scope="col" className="mw-200">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {cashOutHistoryList}
                </tbody>
            </table>
        </div>
    )
    return (
        <div>
            {cashOutHistory.length > 0 ? fullCard : emptyCard}
        </div>
    )
}

function CashOutDeleteButton(props) {
    const alert = props.alert

    async function onClick(event) {
        event.preventDefault();
        const response = await CashOutAPI.destroy(props.cashOutId, props.navigate);

        if (response.success) {
            // Refresh the page
            alert.show("Cash out request deleted",
                { type: 'success' })
            props.navigate(0)
            return
        } else {
            alert.show(response.error.message,
                { type: 'error' })
            return
        }
    }

    return (
        <button type="button" onClick={onClick} className="btn btn-gray btn-delete">Delete</button>
    )
}

export default CashOutHistory;