import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import WarningIcon from '../../../../../assets/images/user_app/warning_triangle_white.png';


// Api Calls
import MissionUserListAPI from "../../api/api_calls/MissionUserListAPI";

function MissionCard(props) {
    const alert = useAlert()
    const navigate = useNavigate();
    const [applied, setApplied] = useState(false);


    useEffect(() => {
        if (props.user_status == "applied") {
            setApplied(true);
        }
    })

    async function submit(e) {
        e.preventDefault();

        const response = await MissionUserListAPI.update(props.mission_id, navigate);

        if (response.success) {
            // Okay, so right now, this is the logic for where to go next.
            if (response.next_path) {
                navigate(response.next_path);
            }
        } else {
            alert.show('Something went wrong. Please try again later.', { type: 'error' })
        }
    }



    return (
        <div className="row mx-2">
            <div className="card rounded-body mission-card bg-tertiary-color outline-tertiary text-white mb-3" >
                <div className="card-body rounded-body bg-tertiary-color text-white px-1 py-1">
                    <div className="row my-2">
                        <div className="col-md-8">
                            <div className="row mission-card-name mt-2 mb-3">
                                <div className="col-4 align-text-right white-right-border max-w-150">
                                    Name
                                </div>
                                <div className="col-8">
                                    {props.mission_name}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4 align-text-right white-right-border max-w-150">
                                    Audience
                                </div>
                                <div className="col-8">
                                    {props.audience_description}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4 align-text-right white-right-border max-w-150">
                                    Format
                                </div>
                                <div className="col-8">
                                    {props.format}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 gray-left-border d-flex flex-column justify-content-center center-text mobile-top-mb3-padding">
                            <div className="reward-title-text mb-1"> Reward</div>
                            <div className="reward-amount-text mb-1"> ${Math.round(props.reward_amount_usd)}</div>
                            <div className="reward-note-text mb-4"> Paid in USDC </div>
                            <div className="justify-content-center">
                                <form onSubmit={submit}>
                                    <ButtonByStyle buttonType={props.user_status} applied={applied} missionType={props.mission_type} />
                                </form>
                            </div>
                            {props.user_status == "rejected" || props.user_status == "completed" ?
                                <div></div> :
                                <SpotsRemainingText number_of_spots={props.number_of_spots} />
                            }
                        </div>
                    </div>
                    {props.mission_locked ?
                        <LockedOverlay locked_wallet_requirements={props.locked_wallet_requirements} />
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default MissionCard

function SpotsRemainingText(props) {
    if (props.number_of_spots < 5) {
        return (
            <div className="reward-note-text">
                <strong>&lt;5</strong> spots remaining
            </div>
        )
    } else if (props.number_of_spots < 20) {
        return (
            <div className="reward-note-text">
                <strong>{props.number_of_spots}</strong> spots remaining
            </div>
        )
    } else {
        return (
            <div className="reward-note-text">
                <strong>More than 20</strong> spots remaining
            </div>
        )
    }
}

function LockedOverlay(props) {
    return (
        <div className="absolute-locked-card">
            <div className="locked-card-content">
                <div className="locked-card-title-row">
                    <img src={WarningIcon} />
                    <div className="locked-card-title">
                        To unlock this mission, verify a wallet that has:
                    </div>
                </div>
                <div className="locked-card-content-row">
                    <Link className="locked-mission-wallet-link" to={{ pathname: "/wallets" }}>
                        Verify Wallet
                    </Link>
                    {/* <div className="locked-card-requirements"> */}
                    <ul className="locked-requirement-list">
                        {props.locked_wallet_requirements.map((string, index) => {
                            return <li key={index} className="locked-requirement-list-item">
                                {string}
                            </li>
                        })}
                    </ul>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

function ButtonByStyle(props) {
    // # List of statuses:
    // # null - line does not exist
    // # prescreen - user has not completed screener
    // # applied - user applied
    // # scheduled - user is scheduled for the mission
    // # rejected - user was rejected
    // # completed - user completed the mission

    // If the user has applied, show the "Applied" button
    const updatedButtonType = props.applied ? "applied" : props.buttonType;


    switch (updatedButtonType) {
        case "none":
            // For surveys, the button should say "Take Survey"
            if (props.missionType == "survey") {
                return (
                    <button type="submit" className="btn btn-primary-color btn-shadow text-white no-outline max-w-200 min-w-142 px-3 my-3">Take Survey</button>
                )
            } else {
                return (
                    <button type="submit" className="btn btn-primary-color btn-shadow text-white no-outline max-w-200 min-w-142 px-3 my-3">Apply</button>
                )
            }
        case "prescreen":
            return (
                <button type="submit" className="btn btn-primary-color btn-shadow text-white no-outline max-w-200 min-w-142 px-3 my-3">Take Screener</button>
            )
        case "in_review":
            return (
                <button type="submit" className="btn btn-primary-color btn-shadow text-white no-outline max-w-200 min-w-142 px-3 my-3">View Status</button>
            )
        case "applied":
            if (props.missionType == "survey") {
                return (
                    <button type="submit" className="btn btn-primary-color btn-shadow text-white no-outline max-w-200 min-w-142 px-3 my-3">Take Survey</button>
                )
            } else if (props.missionType == "exploration") {
                return (
                    <button type="submit" className="btn btn-primary-color btn-shadow text-white no-outline max-w-200 min-w-142 px-3 my-3">Continue</button>
                )
            } else {
                return (
                    <button type="submit" className="btn btn-primary-color btn-shadow text-white no-outline max-w-200 min-w-142 px-3 my-3">Schedule</button>
                )
            }

        case "scheduled":
            return (
                <button type="submit" className="btn btn-primary-color btn-shadow text-white no-outline max-w-200 min-w-142 px-3 my-3">View</button>
            )
        case "rejected":
            return (
                <button type="button" className="btn btn-gray logout-button max-w-200 min-w-142 px-3 my-3 ">Rejected</button>
            )
        case "completed":
            return (
                <button type="submit" className="btn btn-green logout-button max-w-200 min-w-142 px-3 my-3 ">Completed</button>
            )
    }
}