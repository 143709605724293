import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FormField, PrimaryButton, SecondaryButton, OrSeparater, LogInCard, ErrorMessage, NoticeMessage, TurnBackgroundBlackComponent, LogInContainer, LogInCardTitle, LogInCardBody, LogInForm, ForgotPasswordLink, PreAuthPageContainer, SignUpTertiary } from "../components/CommonComponents"
import { UserContext } from "../../UserContext";

// API Calls
import SessionAPI from "../api/api_calls/sessionAPI.js"

function SignIn(props) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [notice, setNotice] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser, refreshUser } = useContext(UserContext);



  useEffect(() => {
    if (location.state) {
      const tempNotice = location.state.notice || "";
      setNotice(tempNotice)
    }
  }, []);

  //Update state on input change
  function onChangeEmail(event) {
    setEmail(event.target.value)
  }
  function onChangePassword(event) {
    setPassword(event.target.value)
  }



  // The log in function called on submit
  function onSubmit(event) {
    event.preventDefault();

    if (email.length == 0 || password.length == 0) { return; }


    const signInUser = async () => {
      const log_hash = {
        user_agent: navigator.userAgent,
        window_outer_width: window.outerWidth,
        window_outer_height: window.outerHeight,
        window_inner_width: window.innerWidth,
        window_inner_height: window.innerHeight,
        screen_width: window.screen.width,
        screen_height: window.screen.height
      }
      const response = await SessionAPI.create(email, password, log_hash, navigate);
      const data = response.data

      if (response.success) {
        refreshUser();
        // We use the javascript here to force it reload.
        // We pass through a rails route to force cSRF token to reload.
        navigate("/dashboard")
        // window.location.href = "/post_sign_in";
      } else {
        // Some errors want a notice instead of an error.
        if (response.reason && response.reason == "unconfirmed") {
          setError("You have not confirmed your email. Please check your email for a confirmation link.")
        } else {
          // sets the error message to display to user
          setError(response.error);
        }
      }

    }

    signInUser();
  }


  return (
    <PreAuthPageContainer>
      <ErrorMessage errors={error} />
      <NoticeMessage notices={notice} />
      <TurnBackgroundBlackComponent />
      <LogInContainer >
        <LogInCard>
          <LogInCardTitle title="Login to Despark" />
          <LogInCardBody >
            <LogInForm onSubmit={(event) => onSubmit(event)} >
              <FormField label="Email" name="email" id="userEmail" placeholder="Email" type="email" autoComplete="username" onChange={onChangeEmail} marginClass="mb-4" />
              <FormField label="Password" name="password" id="userPassword" placeholder="Password" autoComplete="current-password" type="password" onChange={onChangePassword} marginClass="mb-2" />
              <ForgotPasswordLink />
              <div className="mt-32">
                <PrimaryButton text="Login" buttonType="submit" marginClass="mb-3" />
              </div>
            </LogInForm>
            <OrSeparater />
            <div className="d-flex justify-content-center mt-32">
              <SecondaryButton text="Login with wallet" buttonType="submit" onClick={() => navigate("/signin/wallet")} />
            </div>
          </LogInCardBody>
        </LogInCard>
        <SignUpTertiary />
      </LogInContainer>
    </PreAuthPageContainer>
  );

}


//export default SignIn;
export default SignIn