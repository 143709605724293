import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Placeholder from "../menu_components/Placeholder";
import CurrencyInput from 'react-currency-input-field';
import { useAlert } from 'react-alert';
import IsValidEVMAddress from "../../../utility_components/IsValidAddress";

// API Calls
import CashOutAPI from "../../api/api_calls/CashOutAPI";




function CashOutNew(props) {
    const [cashOutAmount, setCashOutAmount] = useState(0.00);
    const [ethAddress, setEthAddress] = useState("");
    const [confirmedEthAddress, setConfirmedEthAddress] = useState("");
    const [blockchain, setBlockchain] = useState("Polygon");

    const alert = useAlert();
    const navigate = useNavigate();


    const onAmountChange = (value) => {
        setCashOutAmount(value);
    }
    const onEthChange = (event) => {
        setEthAddress(event.target.value);
    }
    const onConfirmedEthChange = (event) => {
        setConfirmedEthAddress(event.target.value);
    }

    async function onSubmit(event) {
        event.preventDefault();

        // Make sure cash out amount is less than user's balance 
        // Vetted on back end as well.
        if (parseFloat(cashOutAmount) > parseFloat(props.balance)) {
            alert.show("Request larger than balance",
                { type: 'error' })
            return;
        }

        // Minimum withdrawal amount is $10 
        // Also covers the field being blank
        if (cashOutAmount < 10 && blockchain == "Ethereum") {
            alert.show("Minimum Ethereum cash out is $10",
                { type: 'error' })
            return;
        }

        // Make sure eth address matches confirmed eth address
        if (ethAddress != confirmedEthAddress) {
            alert.show("Ethereum addresses do not match",
                { type: 'error' })
            return;
        }

        // Make sure eth address is valid
        if (IsValidEVMAddress(ethAddress) == false) {
            alert.show("Not a valid Ethereum address",
                { type: 'error' })
            return;
        }

        const response = await CashOutAPI.create(ethAddress, cashOutAmount, blockchain, navigate)

        if (response.success) {
            alert.show("Cash out request created",
                { type: 'success' })
            navigate(0)
        }

    }



    return (
        <div className="card-body rounded-body bg-secondary-color text-white px-3 py-1">
            <form onSubmit={onSubmit} className="form-control bg-transparent no-outline text-white">
                <div className='form-group mb-4 form-width'>

                    <div className="row mt-3">
                        <div className="col-auto triangle-wrapper">
                            <img loading="lazy" src="https://uploads-ssl.webflow.com/62f26ef25631b613f81e4201/6585ac97b5c6458219ee9b28_Right%20Triangle.svg" alt="" className="image-3" />
                        </div>
                        <div className="col">
                            <label className="">Amount to withdraw (paid in USDC)</label>
                            <CurrencyInput
                                id="cashOutAmount"
                                name="cashOutAmount"
                                placeholder="Cash Out Amount ($USDC)"
                                className="form-control bg-primary-color"
                                decimalsLimit={2}
                                onValueChange={(value) => onAmountChange(value)}
                                allowNegativeValue={false}
                                prefix="$"
                            // Good example: onValueChange={(value, name) => onAmountChange(value, name)}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-auto triangle-wrapper">
                            <img loading="lazy" src="https://uploads-ssl.webflow.com/62f26ef25631b613f81e4201/6585ac97b5c6458219ee9b28_Right%20Triangle.svg" alt="" className="image-3" />
                        </div>
                        <div className="col">
                            <label className="">Withdraw to Polygon or Ethereum? Ethereum has a $10 minimum. </label>
                            <select className="form-select custom-select" name="blockchainName" onChange={e => setBlockchain(e.target.value)} id="blockchainName" aria-label="Blockchain Select">
                                <option value="Polygon" key="Polygon">Polygon</option>
                                <option value="Ethereum" key="Ethereum">Ethereum</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-auto triangle-wrapper">
                            <img loading="lazy" src="https://uploads-ssl.webflow.com/62f26ef25631b613f81e4201/6585ac97b5c6458219ee9b28_Right%20Triangle.svg" alt="" className="image-3" />
                        </div>
                        <div className="col">
                            <label className="">Your Ethereum/Polygon wallet address (where we will send USDC) </label>
                            <input name="ethAddress" id="ethAddress" required onChange={onEthChange} className="form-control bg-primary-color" placeholder="Wallet Address" type="input" />
                        </div>
                    </div>
                    <div className="row mt-3 mb-4">
                        <div className="col-auto triangle-wrapper">
                            <img loading="lazy" src="https://uploads-ssl.webflow.com/62f26ef25631b613f81e4201/6585ac97b5c6458219ee9b28_Right%20Triangle.svg" alt="" className="image-3" />
                        </div>
                        <div className="col">
                            <label className="">Confirm wallet Address </label>
                            <input name="confirmedEthAddress" id="confirmedEthAddress" required onChange={onConfirmedEthChange} className="form-control bg-primary-color" placeholder="Confirm Wallet Address" type="input" />
                        </div>
                    </div>
                    <div className="pre-form-note">Cash out requests will be paid in USDC on the specified blockchain (Polygon or Ethereum). </div>
                    <div className="pre-form-note">Please allow up to 7 days for processing. </div>
                    <div className="mt-4">
                        <button type="submit" className='btn btn-primary-color btn-shadow text-white no-outline mx-2 px-5' color='white' size='lg'>
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CashOutNew;