import React, { useEffect, useState, useContext } from "react";
import { Outlet, Routes, Route } from "react-router-dom";
import { UserPortalContainer, AccountColumn } from "../components/LayoutComponents";
import HeaderUser from "../components/menu_components/HeaderUser";

import SurveyContent from "../components/survey_components/SurveyContent";
import ScheduleContent from "../components/schedule_components/ScheduleContent";
import CompletedContent from "../components/completed_components/CompletedContent";
import ScreenerContent from "../components/screener_components/ScreenerContent";
import ReviewContent from "../components/review_components/ReviewContent";
import DefaultContent from "../components/menu_components/DefaultContent";
import ExplorationContent from "../components/exploration_components/ExplorationContent";



function ContentOutline(props) {

  const exampleProp = "This is a prop passed from ScreenerContainer to ScreenerCard"



  return (
    <>
      <HeaderUser />
      <UserPortalContainer>
        <AccountColumn />
        <div className="col px-2">
          <div className="card rounded-body  no-outline bg-secondary-color  text-white my-3" >
            <div className="card-title no-outline px-1 py-1">
              <h3 className="card-header">
                {props.title}
              </h3>
            </div>
            <div className="card-body no-outline profile-style  px-1 py-1">
            </div>
            {/* This renders the right card through the nested Routing in App*/}
            <Routes>
              {/* No id required here. */}
              <Route path='/review/' element={<ReviewContent />} />
              <Route path='/schedule/' element={<ScheduleContent />} />
              <Route path='/completed/' element={<CompletedContent />} />

              {/* This section isn't ideal - I'd prefer missions to do the nesting. Refactor later. */}
              {/*A nested route! - https://dev.to/tywenk/how-to-use-nested-routes-in-react-router-6-4jhd*/}
              <Route path='/surveys/'  >
                <Route path=':surveyId' element={<SurveyContent />} />
                <Route index element={<DefaultContent />} />
              </Route>
              <Route path='/screeners/' >
                <Route path=':screenerId' element={<ScreenerContent />} />
                <Route index element={<DefaultContent />} />
              </Route>


              <Route path='/exploration/' >
                <Route path=':missionId' element={<ExplorationContent />} />
                <Route index element={<DefaultContent />} />
              </Route>

            </Routes>

          </div>
        </div>
      </UserPortalContainer>
    </>

  )
}

export default ContentOutline;