import React from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";
import LogoutButton from "./LogoutButton";


function HeaderOnboarding(props) {
    return (
        <>
            <header>
                <div className="container-fluid p-0 ">
                    <div className="navbar navbar-new navbar-dark bg-black-color bottom-outline-gray">
                        <Link to={{ pathname: "/" }} className="navbar-brand mx-4 px-2 my-3">
                            <strong className="logo-text-new">
                                de<span className="logo-span-highlight-new">spark</span>
                            </strong>
                        </Link>
                        <div className="mx-4">
                            <LogoutButton />
                        </div>
                    </div>
                </div>

            </header>
            <Outlet />
        </>
    )
}

export default HeaderOnboarding;