import React from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";
import LogoutButton from "./LogoutButton";


function HeaderUser(props) {
    return (
        <>
            <header>
                <nav className="navbar navbar-expand-md navbar-dark bg-primary-color bottom-outline-white text-white">
                    <div className="container-fluid">
                        <Link to={{ pathname: "/dashboard" }} className="navbar-brand mx-3 px-2">
                            <strong className="logo-text">
                                de<span className="logo-span-highlight">spark</span>
                            </strong>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                            id="toggleMobileNav" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse navbar-mobile" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto">
                                <li className="nav-item mx-3">
                                    <Link to={{ pathname: "/dashboard" }} className="nav-link mobile-nav-link text-white">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="nav-item mx-3">
                                    <Link to={{ pathname: "/missions" }} className="nav-link mobile-nav-link text-white">
                                        Explore Missions
                                    </Link>
                                </li>
                                {/*                      How to do a dropdown   
                                https://getbootstrap.com/docs/4.3/components/navbar/
                        */}

                            </ul>
                            <ul className="navbar-nav ms-auto nav-link mobile-nav-link ">
                                <li className="nav-item mx-3">
                                    <Link to={{ pathname: "/wallets" }} className="nav-link mobile-nav-link text-white">
                                        My Wallets
                                    </Link>
                                </li>
                                <li>
                                    <LogoutButton />
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

            </header>
            <Outlet />;
        </>
    )
}

export default HeaderUser;