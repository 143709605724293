// import usenavigate
import { apiConfig } from './apiConfig';



// Helper function to add common headers to the API request
export const addCommonHeaders = (headers = {}) => {
    // const token = document.querySelector('meta[name="csrf-token"]').content;

    const commonHeaders = {
        'Content-Type': 'application/json',
        "X-CSRF-Token": getCSRFToken(),
        // Add any other common headers here
    };

    return {
        ...commonHeaders,
        ...headers,
    };
};



export async function handleResponse(response, handleRedirect) {


    // RULES:
    // Always retrun success: true if the response is good
    // Always return success: false otherwise; unless we navigate from here.

    if (response.status === 200 || response.status === 201) { //ok or created
        const data = await response.json();
        data["success"] = true
        return data;

    } else if (response.status === 204) { //  no content
        // For successful POST or PUT requests without any response body
        return { success: true };

    } else if (response.status === 302) { // 302 Found - Temporary Redirect
        // For redirects, we return the redirect path
        // In the future, allow components to pass in a callback function 
        // to handle the redirects
        const data = await response.json();

        if (data.redirect_path) {
            handleRedirect(data.redirect_path)
            return {}
        } else {
            throw new Error('Redirect: Redirect path not found.');
        }

    } else if (response.status === 400) {
        // Bad Request
        const errorData = await response.json();
        throw new Error(`Bad Request: ${errorData.message || 'Unknown error'}`);

    } else if (response.status === 401) {
        // Unauthorized
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            const errorData = await response.json();
            throw new Error(`Unauthorized: ${errorData.message || 'Unknown error'}`);
        } else {
            const errorMessage = await response.text();
            throw new Error(`${errorMessage || 'Unknown error. Please try again later.'}`);
        }
    } else if (response.status === 403) {
        // Forbidden
        throw new Error('Forbidden: You do not have permission to access this resource.');
    } else if (response.status === 404) {
        // Not Found
        throw new Error('Not Found: The requested resource was not found.');
    } else if (response.status === 422) {
        // Unprocessable Entity
        const errorData = await response.json();
        const errorMessages = CleanUpErrors(errorData.errors)
        throw new Error(`Unprocessable Entity: ${errorMessages || 'Unknown error'}`);
    } else if (response.status === 500) {
        // Internal Server Error
        throw new Error('Internal Server Error: Something went wrong on the server.');
    } else {
        throw new Error(`Request failed with status ${response.status}. Please contact info@despark.io`);
    }
};

export const handleErrors = (error) => {
    console.log('API Error:', error);
    return { success: false, error: error.message };
};

// ----------------------------------
// ----------------------------------
// -------- Helper Functions --------
// ----------------------------------
// ----------------------------------


// Clean up 422 errors
function CleanUpErrors(errors) {
    if (errors) {
        let failureReasons = ""
        if (errors.constructor === Object) {
            failureReasons = CleanUpMultipleErrors(errors)
        } else {
            failureReasons = errors
        }
        return failureReasons
    } else {
        return "Unable to complete request. Please try again later. If this issue persists, contact us at info@despark.io"
    }
}

function CleanUpMultipleErrors(errors) {

    var errorString = "";
    Object.keys(errors).forEach(function (key) {
        const objectName = key == "email" ? "Email" : (key == "password" ? "Password" : (key == "password_confirmation" ? "Password Confirmation" : key))
        errorString += objectName + " " + errors[key] + "\n";
    });
    return errorString
}

function getCSRFToken() {
    const cookies = document.cookie;
    const splitCookies = cookies.split('; ');
    const desparkToken = splitCookies.find(cookie => cookie.startsWith("_despark_token="))
    return desparkToken ? desparkToken.split('=')[1] : null
}

