import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Placeholder from "../menu_components/Placeholder";
import ContentCashOut from "./ContentCashOut";
import CashOutNew from "./CashOutNew";
import CashOutAPI from "../../api/api_calls/CashOutAPI";


function CardCashOut(props) {

  const [cashOutHistory, setCashOutHistory] = useState([]);
  const [loadCashOutListComplete, setLoadCashOutListComplete] = useState(false);
  const [activeTab, setActiveTab] = useState("history");



  function updateTab(tab) {
    setActiveTab(tab);
  }

  useEffect(() => {
    //Grab this user's history of cash outs
    const cashOutHistoryData = async () => {
      const response = await CashOutAPI.index();

      if (response.success) {
        const cashOutHistory = response.cash_outs ? response.cash_outs : [];
        setCashOutHistory(cashOutHistory);
        setLoadCashOutListComplete(true);
      }

    }

    cashOutHistoryData()
  }, []);


  const activeClass = "nav-link active card-nav text-white";
  const inactiveClass = "nav-link card-nav text-white";

  return (
    <div className="card rounded-body  no-outline bg-secondary-color half-screen-height  text-white my-3" >
      <div className="card-title no-outline px-1 py-1">
        <h3 className="card-header">
          Cash Out Requests
        </h3>
      </div>
      <div className="card-body no-outline profile-style  px-1 py-1">
        <ul className="nav nav-tabs no-bottom-border mx-2 mb-3">
          <li className="nav-item">
            <div className={activeTab == "new" ? activeClass : inactiveClass}
              onClick={() => updateTab("new")}>
              Create New
            </div>
          </li>
          <li className="nav-item">
            <div className={activeTab == "history" ? activeClass : inactiveClass}
              onClick={() => updateTab("history")}>
              History
            </div>
          </li>
        </ul>
        {!loadCashOutListComplete ?
          <Placeholder /> :
          <ContentCashOut activeTab={activeTab} balance={props.balance} cashOutHistory={cashOutHistory} />
        }
      </div>
    </div>

  )
}



export default CardCashOut;