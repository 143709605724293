


// // Sign up the User
async function SignUpApiCall(body) {
    const url = "/users/signupnew";
    const token = document.querySelector('meta[name="csrf-token"]').content;

    try {
        const myResponse = await fetch(url, {
            method: "POST",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            },
            body: body
        })

        if (myResponse.ok) {
            const responseBody = await myResponse.json();

            const returnData = {
                userEmail: responseBody.user_email,
                success: true,
                next_path: responseBody.next_path,
                failureReason: []
            }
            return returnData

        } else if (myResponse.status == 422) {
            const responseBody = await myResponse.json();
            let failureReasons = null;
            // Sometimes Rails returns a dcitionary of errors. Handle it. 
            if (responseBody.errors.constructor === Object) {
                failureReasons = responseBody.errors ? CleanUpErrors(responseBody.errors) : ["Unable to complete request. Please try again later. If this issue persists, contact us at info@despark.io"]
            } else {
                failureReasons = responseBody.errors ? responseBody.errors : "Something went wrong."
            }
            const returnData = {
                success: false,
                failureReasonArray: failureReasons
            }
            return returnData
        } else {
            console.log("Other Issue")
            throw new Error("Network response was not ok.");
        }

    } catch (error) {
        const returnData = {
            success: false,
            failureReasonArray: ["Unable to complete request. Please try again later. If this issue persists, contact us at info@despark.io."]
        }
        return returnData
    }
}

function CleanUpErrors(errors) {
    var errorArray = [];
    Object.keys(errors).forEach(function (key) {
        const objectName = key == "email" ? "Email" : (key == "password" ? "Password" : "Password Confirmation")
        errorArray.push(objectName + " " + errors[key]);
    });
    return errorArray
}

export default SignUpApiCall;