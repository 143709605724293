import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {
    update: async (missionId, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "missionuserlists/";

        const body = JSON.stringify({
            mission_user_list: {
                mission_id: missionId
            }
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

}