import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {

    create: async (email, password, password_confirmation, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "users/signupnew";

        const body = JSON.stringify({
            user: {
                email,
                password,
                password_confirmation,
            }
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    createWithWallet: async (email, password, password_confirmation, wallet_address, signature_data, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "users/signupnew";

        const body = JSON.stringify({
            user: {
                email: email,
                password: password,
                password_confirmation: password_confirmation
            },
            wallet: {
                signature: signature_data,
                address: wallet_address
            }
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    getNonce: async (wallet_address, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "users/getnonce/" + wallet_address;

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    checkWalletExists: async (wallet_address, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "users/checkwallet";

        const body = JSON.stringify({
            user_wallet: {
                address: wallet_address
            }
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },





}