import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {
  createCashOut: async (missionId, walletAddress, blockchain, handleRedirect = null) => {
    const url = apiConfig.apiUrl + missionId + "/cash_outs";


    const body = JSON.stringify({
      cash_out: {
        eth_address: walletAddress,
        blockchain: blockchain
      }
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: addCommonHeaders(),
        body: body,
        credentials: 'include',
      })

      return await handleResponse(response, handleRedirect);

    } catch (error) {
      return handleErrors(error);
    }
  },

  getCashOuts: async (missionId, handleRedirect = null) => {
    const url = apiConfig.apiUrl + missionId + "/cash_outs";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: addCommonHeaders(),
        credentials: 'include',
      })

      return await handleResponse(response, handleRedirect);

    } catch (error) {
      return handleErrors(error);
    }
  },

  deleteCashOut: async (missionId, cashOutId, handleRedirect = null) => {
    const url = apiConfig.apiUrl + missionId + "/cash_outs/" + cashOutId;

    const body = JSON.stringify({
      cash_out: {
        id: cashOutId,
      }
    });

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: addCommonHeaders(),
        body: body,
        credentials: 'include',
      })

      return await handleResponse(response, handleRedirect);

    } catch (error) {
      return handleErrors(error);
    }
  },

}