import React, { useState, useEffect, useCallback } from "react";
import SurveyContent from "../user_portal/components/survey_components/SurveyContent";
import { useAlert } from "react-alert";




function AdminSurvey(props) {

    return (
        <SurveyContent adminResponse={true} />
    )

}

export default AdminSurvey