import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {

    create: async (email, password, log_hash = null, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "users/signin";


        const body = JSON.stringify({
            user: {
                email,
                password
            },
            sign_in_log: log_hash
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    createFromWallet: async (wallet_address, signature_data, log_hash = null, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "users/signin";

        const body = JSON.stringify({
            signature: {
                signature_data: signature_data,
                address: wallet_address
            },
            sign_in_log: log_hash
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    destroy: async (handleRedirect = null) => {
        const url = apiConfig.apiUrl + "users/signout";

        try {
            const response = await fetch(url, {
                method: "DELETE",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    checkUser: async (handleRedirect = null) => {
        // This doesn't use the api/v1 format. 
        const url = "/users/checkuser";

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    userInfo: async (handleRedirect = null) => {
        // This doesn't use the api/v1 format. 
        const url = "/users/user_info";

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })
            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

}