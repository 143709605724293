import React, { useState, useEffect, useCallback, useReducer } from "react";
import { MissionDetailsHighlight } from "../../overview_components/CommonOverviewComponents";
import { PrimaryButtonSmall } from "../../../preauth_portal/components/CommonComponents";


function PublicScheduledSessionList(props) {
    const fiveMinutes = 5 * 60 * 1000
    const startTimeAsDate = new Date(props.start_time)
    const timeDifferenceFromNowToStart = (startTimeAsDate) - (new Date()) // Time difference from start time to now in milliseconds

    const timeToWait = timeDifferenceFromNowToStart - fiveMinutes

    const [joinButtonActive, setJoinButtonActive] = useState(timeDifferenceFromNowToStart < fiveMinutes);

    // ----------------------------------------
    // ----------------------------------------
    // --------------- UseEffects -------------
    // ----------------------------------------
    // ----------------------------------------

    // Set a Timeout to activcate teh button when  
    // we're within five minutes of the meeting start time
    useEffect(() => {
        if (timeToWait > 0) {
            const buttonTimer = setTimeout(() => {
                setJoinButtonActive(true)
            }, timeToWait);

            return () => clearTimeout(buttonTimer);
        }
    }, [])


    // ----------------------------------------
    // ----------------------------------------
    // --------------- Helpers ----------------
    // ----------------------------------------
    // ----------------------------------------

    function RescheduleButton() {
        return (
            <a className="text-link-new" onClick={() => props.reschedule()}>
                Reschedule
            </a>
        )
    }

    function CancelButton() {
        return (
            <a className="text-link-new" onClick={() => props.cancel()}>
                Cancel
            </a>
        )
    }

    function joinMeeting() {
        window.open(props.meetingUrl, '_blank');
    }

    return (
        <>
            <div className="body-1">
                You've scheduled a time for your feedback session. After attending and completing the session, you'll be sent an email with details of your reward. Your email is “{props.email}” and pseudonym is "{props.name}".
            </div>
            <MissionDetailsHighlight>
                <div className="d-flex flex-row flex-wrap align-items-center column-gap-4 row-gap-3">
                    <div className="body-1">
                        Selected Time:
                    </div>
                    <div className="body-1">
                        {props.selectedDate} @ {props.selectedTime}
                    </div>
                    <RescheduleButton />
                    <CancelButton />
                </div>
            </MissionDetailsHighlight>
            <div className="py-3">
                <PrimaryButtonSmall type="button" text="Join Meeting" onClick={() => joinMeeting()} isDisabled={!joinButtonActive} />
            </div>
        </>
    )
}

export default PublicScheduledSessionList;