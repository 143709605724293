import React, { useState, } from "react";
import { MissionDetailsHighlight, VertSpacer } from "../../overview_components/CommonOverviewComponents";
import { PrimaryButton, PrimaryButtonSmall, FormField } from "../../../preauth_portal/components/CommonComponents";

function EmailSelect(props) {
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")


    function onChange(event) {
        if (event.target.name == "email") {
            setEmail(event.target.value)
        } else if (event.target.name == "name") {
            setName(event.target.value)
        }
    }

    function ChangeButton(props) {

        return (
            <a className="text-link-new" onClick={props.onClick}>
                Change
            </a>
        )
    }

    return (
        <>
            <MissionDetailsHighlight>
                <div className="d-flex flex-row align-items-center column-gap-4">
                    <div className="body-1">
                        Selected Time:
                    </div>
                    <div className="body-1">
                        {props.selectedDate} @ {props.selectedTime}
                    </div>
                    <ChangeButton onClick={() => props.setSelectingEmail(false)} />
                </div>
            </MissionDetailsHighlight>
            <VertSpacer />
            <div>
                <h5>
                    Where should we send the invite?
                </h5>
                <div className="body-3 color-gray">
                    We will ONLY send you information about this session, including an invitation and information on the session and reward. We will NOT share your email.
                </div>
            </div>
            <div className="row">
                <div className="col col-sm-6 col-lg-4">
                    <FormField label="Email" name="email" type="email" placeholder="Enter your email" onChange={onChange} autoComplete="off" />
                </div>
            </div>
            <div className="row">
                <div className="col col-sm-6 col-lg-4">
                    <FormField label="Pseudonym or First Name" name="name" type="text" placeholder="Pseudonym or First Name" onChange={onChange} autoComplete="off" marginClass="mb-4" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <PrimaryButtonSmall type="button" text={props.submitting ? "Loading" : "Submit"} onClick={() => props.onSubmit(email, name)} disabled={props.submitting} />
                </div>
            </div>
        </>
    )
}

export default EmailSelect;