import React, { useState, useEffect, useCallback, useReducer } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Placeholder from '../menu_components/Placeholder';

// Api Calls
import MissionAPI from "../../api/api_calls/MissionAPI";





function ReviewContent(props) {
    const [missionLoaded, setMissionLoaded] = useState(false);
    const [missionFormat, setMissionFormat] = useState("");
    const [missionTitle, setMissionTitle] = useState("");
    const [missionReward, setMissionReward] = useState("0.0");
    const [missionAudience, setMissionAudience] = useState("");

    let params = useParams()
    const missionId = params.missionId;


    // Load needed data
    useEffect(() => {
        //Grab information on this user
        const GetSchedule = async () => {
            const data = await MissionAPI.show(missionId);

            setMissionLoaded(true);
            setMissionFormat(data.mission.format_descriptive);
            setMissionTitle(data.mission.name);
            setMissionReward(data.mission.reward_amount_usd);
            setMissionAudience(data.mission.audience_description);
        }

        GetSchedule();
    }, []);


    function BackToMissionLink() {
        return (
            <div className="mt-5 mb-3">
                <Link to={{ pathname: "/missions" }}
                    className="text-white px-0 my-4">
                    Back to Missions
                </Link>
            </div>
        )

    }

    // Set up the events portion
    function ReviewComponent() {
        // If not loaded, show placeholder.
        if (!missionLoaded) {
            return (<Placeholder />)
        } else {
            return (<ReviewText />)
        }
    }



    // Return function for ScheduleContent
    return (
        <div className="row mx-4 text-white">
            <div className="surveytitle px-4 py-4">
                {missionLoaded ? missionTitle : "Loading..."}
                <div className="scheduleSubTitle">
                    <span style={{ color: "#858585" }} >Format: </span>
                    {missionLoaded ? missionFormat : "Loading..."}
                </div>
                <div className="scheduleSubTitle">
                    <span style={{ color: "#858585" }} >Reward: </span>
                    {missionLoaded ? "$" + Math.round(parseFloat(missionReward)) : "Loading..."}
                </div>
                <div className="scheduleSubTitle">
                    <span style={{ color: "#858585" }} >Audience: </span>
                    {missionLoaded ? missionAudience : "Loading..."}
                </div>
            </div>
            {/* This is where the logic renders. */}
            {/* If events -> show it. */}
            {/* If no event, then pull  */}
            <div className="px-4 my-4 py-2">
                {ReviewComponent()}
                {BackToMissionLink()}
            </div>
        </div>
    )

}


function ReviewText() {
    return (

        <div className="row my-4 mx-0">
            <div className="my-2 px-0">
                You've successfully applied for this mission. The researchers for this mission will review your profile and get back to you shortly.
                If approved, We'll reach out via email to schedule a time for you to complete the mission.
            </div>
            <div className="my-2 px-0">
                Questions or concerns? Please contact us at info@despark.io
            </div>
        </div>
    )
}


// This function is called when the page is loaded 
async function ScheduleApiCall(missionId) {
    const url = "/api/v1/missions/" + missionId;
    const token = document.querySelector('meta[name="csrf-token"]').content;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        if (response.ok) {
            const body = await response.json();

            return body
        } else {
            throw new Error("Network response was not ok.");
        }
    } catch (error) {
        console.log(error.message)
        return { error: true }
    }
}




export default ReviewContent