import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Survey, Model, StylesManager } from "survey-react";
import 'survey-react/defaultV2.min.css';

import { PrimaryButtonSmall } from "../../../preauth_portal/components/CommonComponents";
import LoadingIcon from "../../standard_components/LoadingIcon";


// API Calls
import SurveyAPI from "../../api/api_calls/SurveyAPI";




function PublicScreener(props) {
    const [screener, setScreener] = useState(null);
    const [screenerComplete, setScreenerComplete] = useState(false);
    const [failedLoading, setFailedLoading] = useState(false);
    const [screenerFailed, setScreenerFailed] = useState(false);
    const [screenerLoaded, setScreenerLoaded] = useState(false);
    const [screenerId, setScreenerId] = useState(null);
    const stateRef = useRef();

    stateRef.current = screenerId;


    let params = useParams()
    const missionId = params.missionId;
    StylesManager.applyTheme("defaultV2");
    const navigate = useNavigate();



    // On complete, save the screener results
    const onScreenerComplete = useCallback(async (sender) => {

        setScreenerComplete(true);

        const response = await SurveyAPI.createSurveyResponse(
            missionId,
            stateRef.current, // This lets us send the screenerId to the API call (otherwise it's stale and null)
            sender.data, // This is the survey data
            navigate
        )

        if (!response.pass) {
            // Set it to failed and show the user the failed status.
            setScreenerFailed(true);
        } else {
            navigate(response.next_path)
        }

    }, []);

    // Load the screener
    useEffect(() => {
        // Grab information on this user
        async function GetScreener() {
            const data = await SurveyAPI.getSurvey(missionId, "screener", navigate);

            if (data.success == false) {
                setFailedLoading(true);
                return;
            }
            // Once it loads, then run through and set all the screener stuff
            const tempScreener = new Model(await data.survey);
            tempScreener.onComplete.add(onScreenerComplete);
            setScreener(tempScreener);
            setScreenerLoaded(true);
            setScreenerId(data.survey_id);
        }

        GetScreener();
    }, []);


    return (
        <>
            <div className="">
                Screener required:     To help us gather the most relevant insights, please complete this short screener before proceeding to the main feedback session.
            </div>
            <SwitchCompleteAndFailedScreener failedLoading={failedLoading} screenerComplete={screenerComplete} screenerLoaded={screenerLoaded} screener={screener} screenerFailed={screenerFailed} navigate={navigate} missionId={missionId} />
        </>
    )

}

function SwitchCompleteAndFailedScreener(props) {
    const lastRoute = "/public/missions/" + props.missionId;
    if (props.failedLoading) {
        return (
            <div className="surveyComplete d-flex flex-column align-items-center justify-content-center my-4 py-5">
                <div className="pt-4">
                    Screener not available at this time.
                </div>
                <PrimaryButtonSmall text="Back to Overview" buttonType="button" onClick={() => props.navigate(lastRoute)} />
            </div>
        )
    } else if (props.screenerFailed) {
        return (
            <div className="surveyComplete d-flex flex-column align-items-center justify-content-center my-4 py-5">
                <div className="pt-4 mb-3">
                    Unfortunately, you aren't the target audience for this feedback session. Thank you for your interest.
                </div>
                <PrimaryButtonSmall text="Back to Overview" buttonType="button" onClick={() => props.navigate(lastRoute)} />

            </div>
        )
    } else if (props.screenerComplete) {
        return (
            <div className="surveyComplete d-flex flex-column align-items-center justify-content-center my-4 py-5">
                <div className="pt-4">
                    Thank you for completing the screener. Saving your responses...
                </div>
            </div>
        )
    } else {
        return (
            <div className="black-bg-survey" id="surveyElement">
                {props.screenerLoaded ? <Survey model={props.screener} /> : <LoadingIcon />}
            </div>
        )
    }
}



export default PublicScreener