import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ButtonSmallCTA(props) {

    return (
        <Link to={{ pathname: props.path }}
            className="btn-sm btn-primary-color btn-shadow text-white no-outline">
            {props.text}
        </Link>

    )

}

export default ButtonSmallCTA