import React, { useState, useEffect, useCallback, useReducer } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useAlert } from 'react-alert';
import Placeholder from '../menu_components/Placeholder';

// Api Calls
import MissionAPI from "../../api/api_calls/MissionAPI";





function CompletedContent(props) {
    const [missionLoaded, setMissionLoaded] = useState(false);
    const [missionFormat, setMissionFormat] = useState("");
    const [missionTitle, setMissionTitle] = useState("");
    const [missionReward, setMissionReward] = useState("0.0");
    const [missionAudience, setMissionAudience] = useState("");

    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);

    const exampleProp = useOutletContext();
    let params = useParams()
    const missionId = params.missionId;
    const alert = useAlert();
    const navigate = useNavigate();

    const [availabilityLoaded, setAvailabilityLoaded] = useState(false);
    const [missionEvent, setMissionEvent] = useState(null);
    const [timezone, setTimezone] = useState("");
    const [appointmentList, setAppointmentList] = useState({});
    const [showReschedule, setShowReschedule] = useState(false);

    // Load needed data
    useEffect(() => {
        //Grab information on this user
        const GetSchedule = async () => {
            const response = await MissionAPI.show(missionId, navigate);

            if (response.success) {
                // if (response.mission.status != "completed") {
                //     alert.error("This mission is not completed. It may still be processing. Please check back later.")
                // } else {
                setMissionLoaded(true);
                setMissionFormat(response.mission.format_descriptive);
                setMissionTitle(response.mission.name);
                setMissionReward(response.mission.reward_amount_usd);
                setMissionAudience(response.mission.audience_description);
                setUpcomingEvents(response.upcoming_events)
                setPastEvents(response.past_events)
                // }
            } else {
                alert.error("Unable to load mission details. Please try again later.")
            }
        }

        GetSchedule();
    }, []);


    function BackToMissionLink() {
        if (!showReschedule) {
            return (
                <div className="mt-5 mb-3">
                    <Link to={{ pathname: "/missions" }}
                        className="text-white px-0 my-4">
                        Back to Missions
                    </Link>
                </div>
            )
        } else {
            return <></>
        }

    }

    // Set up the events portion
    function eventComponent() {
        // If not loaded, show placeholder.
        if (!missionLoaded) {
            return (<Placeholder />)

            // If loaded, and we have events, then show them. 
        } else if (pastEvents.length > 0 && !showReschedule) {
            // ONLY SHOWS PAST COMPLETED EVENTS
            return (<ShowEvents events={pastEvents} title="Completed Events" />)
        } else {
            return (<></>)
        }
    }



    // Return function for ScheduleContent
    return (
        <div className="row mx-4 text-white">
            <div className="surveytitle px-4 py-4">
                {missionLoaded ? missionTitle : "Loading..."}
                <div className="scheduleSubTitle">
                    <span style={{ color: "#858585" }} >Format: </span>
                    {missionLoaded ? missionFormat : "Loading..."}
                </div>
                <div className="scheduleSubTitle">
                    <span style={{ color: "#858585" }} >Reward: </span>
                    {missionLoaded ? "$" + Math.round(parseFloat(missionReward)) : "Loading..."}
                </div>
                <div className="scheduleSubTitle">
                    <span style={{ color: "#858585" }} >Audience: </span>
                    {missionLoaded ? missionAudience : "Loading..."}
                </div>
            </div>
            {/* This is where the logic renders. */}
            {/* If events -> show it. */}
            {/* If no event, then pull  */}
            <div className="px-0 px-md-4 mt-4 pt-3 mb-2 ">
                You've completed this mission! Your balance has been updated. Issues? Contact us at <a className="white-link" href="mailto:info@despark.io">info@despark.io</a>
            </div>
            <div className="px-0 px-md-4 my-3 py-3">
                {eventComponent()}
                {BackToMissionLink()}
            </div>
        </div>
    )

}

// This function will return the html based on whether events is empty or not
function ShowEvents(props) {
    const events = props.events;

    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    const eventList = events.map((event, index) => (
        <tr key={index}>
            <td className="px-2">{new Date(event.start_time).toLocaleDateString('en-us', { year: "numeric", month: "long", day: "numeric" })}</td>
            <td className="px-2"> {new Date(event.start_time).toLocaleTimeString('en-us', { hour: "numeric", minute: "numeric", hour12: true })}</td>
            <td className="px-2"> {new Date(event.end_time).toLocaleTimeString('en-us', { hour: "numeric", minute: "numeric", hour12: true })}</td>
            <td className="px-2"> {toTitleCase(event.status)}</td>
            <td className="px-2">{event.event_type}</td>
            {props.title == "Upcoming Events" ?
                (
                    <td className="px-2">
                        <div className="btn btn-sm btn-secondary" onClick={() => props.rescheduleFunction()}>Reschedule</div>
                    </td>
                ) : (<></>)
            }
            {props.title == "Upcoming Events" ?
                (
                    <td className="px-2">
                        <div className="btn btn-sm btn-secondary" onClick={() => props.cancelFunction(event.id)}>Cancel</div>
                    </td>
                ) : (<></>)
            }
        </tr>
    ));

    const fullCard = (
        <>
            <div className="table-wrapper table-responsive rounded-corners mx-0 mt-4 mb-0">
                <table className="table table-sm table-secondary-gray table-hover white-text">
                    <thead>
                        <tr>
                            <th scope="col" className="px-2">Date</th>
                            <th scope="col" className="px-2">Start Time</th>
                            <th scope="col" className="px-2">End Time</th>
                            <th scope="col" className="px-2">Status</th>
                            <th scope="col" className="px-2">Format</th>
                            {props.title == "Upcoming Events" ? (
                                <th scope="col" className="px-2">Reschedule</th>
                            ) : (<></>)}
                            {props.title == "Upcoming Events" ? (
                                <th scope="col" className="px-2">Cancel</th>
                            ) : (<></>)}
                        </tr>
                    </thead>
                    <tbody>
                        {eventList}
                    </tbody>
                </table>
            </div>
            <div className="table-note mb-5">
                Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}
            </div>
        </>
    )

    const noteSection = !props.note ? <></> : (
        <div className="my-2 px-0 schedule-note">
            {props.note}
        </div>
    )

    // if (events.length > 0) {
    return (
        <>
            <div className="header2 px-0 py-2">
                {props.title}
            </div>
            <div className="scheduleSubTitle">
                {fullCard}
            </div>
            {noteSection}
        </>
    )
    // } else {
    //     return (
    //         <div className="pt-4">
    //             You've successfully applied for this mission. We'll reach out via email shortly
    //             to schedule a time for you to complete the mission.
    //             <div className="my-2">
    //                 Questions or concerns? Please contact us at info@despark.io
    //             </div>
    //         </div>
    //     )
    // }
}



export default CompletedContent