import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {

    getSurvey: async (missionId, surveyType = "", handleRedirect = null) => {
        // This function is called when the screener is loaded and grabs the screener from the server
        const paramString = surveyType ? "?survey_type=" + surveyType : ""
        const url = apiConfig.apiUrl + missionId + "/survey" + paramString;

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include'
            })

            return await handleResponse(response, handleRedirect);
        } catch (error) {
            return handleErrors(error);
        }
    },

    createSurveyResponse: async (missionId, surveyId, json, handleRedirect = null) => {
        const url = apiConfig.apiUrl + missionId + "/survey_responses";

        const body = JSON.stringify(
            {
                survey_response: json,
                survey_id: surveyId
            })


        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                credentials: 'include',
                body: body
            })

            return await handleResponse(response, handleRedirect);
        } catch (error) {
            return handleErrors(error);
        }
    },

}