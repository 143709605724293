import React, { useState, useEffect } from "react";
import { MissionDetailsHighlight, VertSpacer } from "../../overview_components/CommonOverviewComponents";


function PublicRejected(props) {

    // ----------------------------------------
    // ----------------------------------------
    // --------------- UseEffects ----------------
    // ----------------------------------------
    // ----------------------------------------


    // ----------------------------------------
    // ----------------------------------------
    // --------------- Helpers ----------------
    // ----------------------------------------
    // ----------------------------------------



    return (
        <>
            <MissionDetailsHighlight>
                Unfortunately, you are not the target audience for this mission. Thanks for your interest in giving feedback.
            </MissionDetailsHighlight>
            <VertSpacer />
        </>
    )

}

export default PublicRejected;