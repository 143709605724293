import React from "react";
import GrayCircleIcon from '../../../../../assets/images/mission_detail_icons/gray_circle_icon.svg';
import GreenCircleIcon from '../../../../../assets/images/mission_detail_icons/green_circle_icon.svg';
import RedCircleIcon from '../../../../../assets/images/mission_detail_icons/red_circle_icon.svg';


function WalletRequirements(props) {



    return (
        props.requirements.length === 0 ?
            <WalletRequirementsEmpty company={props.company} />
            :
            <WalletRequirementsFull company={props.company} requirements={props.requirements} />

    )
}

function WalletRequirementsEmpty(props) {

    return (
        <div className="row d-flex flex-column row-gap-4">
            <div className="body-1">
                {props.company ? props.company + " is" : "We are"} interested in feedback from users.
            </div>
        </div>
    )
}

function WalletRequirementsFull(props) {

    const walletRequirements = props.requirements.map((requirement, index) => {
        return (
            <WalletRequirement status={requirement.status} text={requirement.text} key={"req" + index} />

        )
    });

    return (
        <div className="row d-flex flex-column row-gap-4">
            <div className="body-1">
                {props.company ? props.company + " is" : "We are"} interested in feedback from users that meet
                ALL of the following requirements:
            </div>
            <div className="col">
                <div className="requirements-list d-flex flex-column align-items-start row-gap-2">
                    {walletRequirements}
                </div>
            </div>
        </div>
    )
}

function WalletRequirement(props) {
    const classColor = props.status ? "requirement-green" : props.status === null ? "requirement-gray" : "requirement-red";
    const icon = props.status ? GreenCircleIcon : props.status === null ? GrayCircleIcon : RedCircleIcon;

    return (
        <div className={"requirement-item " + classColor + " w-auto d-flex flex-row py-1 px-2"}>
            <div className="requirement-item-icon">
                <img src={icon} />
            </div>
            <div className="body-1 requirement-item-text">
                {props.text}
            </div>
        </div>

    )
}

export default WalletRequirements;