import React, { useContext } from "react";
import { UserContext } from "../../UserContext";
import CardAccount from "./dashboard_components/CardAccount";


function UserPortalContainer(props) {
    // Commenting to get it to save
    return (
        <div className="container-fluid primary-color">
            <div className="row mx-2">
                {props.children}
            </div>
        </div>
    )
}

function AccountColumn(props) {
    const { user, setUser, refreshUser } = useContext(UserContext);

    return (
        <div className="col-sm-auto d-flex flex-column px-2">
            <CardAccount balance={user.balance} email={user.email} created_at={user.created_at}
                demo_exists={user.extended_profile_complete} loadProfileComplete={true}
                bonusInterviewBalance={user.bonus_completion_balance_interview}
                bonusSurveyBalance={user.bonus_completion_balance_survey}
            />
        </div>
    )
}



export { UserPortalContainer, AccountColumn }