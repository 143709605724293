import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {
  // Get the signature message based on this wallet
  getSignatureMessage: async (wallet_address, mission_id, handleRedirect = null) => {
    const url = apiConfig.apiUrl + mission_id + "/get_signature_message";

    const body = JSON.stringify({
      wallet_address: wallet_address
    })

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: addCommonHeaders(),
        credentials: 'include',
        body: body
      })
      return await handleResponse(response, handleRedirect);

    } catch (error) {
      result = handleErrors(error);
      result["signature_message"] = null
      return result
    }
  },

  // Check if the user is on the correct page when they click into the app
  checkInitialPage: async (pathname, mission_id, handleRedirect = null) => {
    const lastUrlSlug = pathname.split("missions/" + mission_id)[1]
    const url = apiConfig.apiUrl + mission_id + "/check_page?current_page=" + lastUrlSlug;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: addCommonHeaders(),
        credentials: 'include',
      })

      return await handleResponse(response, handleRedirect);

    } catch (error) {
      return handleErrors(error);
    }
  },

  // Create a new session for this user
  createSession: async (wallet_address, signature_data, mission_id, handleRedirect = null) => {
    const url = apiConfig.apiUrl + mission_id + "/session";

    const body = JSON.stringify({
      wallet: {
        address: wallet_address,
        signature: signature_data
      }
    })

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: addCommonHeaders(),
        body: body,
        credentials: 'include',
      })

      return await handleResponse(response, handleRedirect);


    } catch (error) {
      return handleErrors(error);
    }
  },

  // Destroy the session for this user
  destroySession: async (mission_id, handleRedirect = null) => {
    const url = apiConfig.apiUrl + mission_id + "/session";

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: addCommonHeaders(),
        credentials: 'include',
      })

      return await handleResponse(response, handleRedirect);


    } catch (error) {
      return handleErrors(error);
    }
  },

}

