import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {
    create: async (email, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "users/password";


        const body = JSON.stringify({
            user: {
                email
            }
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    update: async (password, password_confirmation, reset_password_token, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "users/password";


        const body = JSON.stringify({
            user: {
                password,
                password_confirmation,
                reset_password_token,
                commit: "Update password"
            }
        });

        try {
            const response = await fetch(url, {
                method: "PUT",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },





}