import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {
    update: async (age, education, industry, jobTitle, income, gender, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "userdemographic";



        const body = JSON.stringify({
            age: age,
            education_id: education,
            industry_id: (industry == 0 ? null : industry),
            job_title: jobTitle,
            income_id: income,
            gender_id: gender
        });

        try {
            const response = await fetch(url, {
                method: "PUT",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    show: async (handleRedirect = null) => {
        const url = apiConfig.apiUrl + "userdemographic";

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },


}