import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {
    show: async (surveyId, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "surveys/" + surveyId;

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    create_response: async (surveyData, surveyId, isScreener, handleRedirect = null) => {
        let url_ending = "/survey_responses"
        // if (isScreener) { // NO LONGER NEEDED
        //     url_ending = "/screener_responses"
        // }
        const url = apiConfig.apiUrl + "surveys/" + surveyId + url_ending;
        const body = JSON.stringify({
            survey_response: surveyData
        })


        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },


}