import React, { useEffect, useState, useContext } from "react";
import CardAccount from "../components/dashboard_components/CardAccount";
import CardCashOut from "../components/cashout_components/CardCashOut";
import { UserContext } from "../../UserContext";

import { UserPortalContainer, AccountColumn } from "../components/LayoutComponents";
import HeaderUser from "../components/menu_components/HeaderUser";

function CashOutPage(props) {
  const { user, setUser, refreshUser } = useContext(UserContext);

  return (

    <>
      <HeaderUser />
      <UserPortalContainer>
        <AccountColumn />
        <div className="col px-2">
          <CardCashOut balance={user.balance} />
        </div>
      </UserPortalContainer>
    </>


  )
}

export default CashOutPage;