import React, { useState, useEffect, useCallback, useReducer } from "react";
import AppointmentSubmitButton from "./AppointmentSubmitButton";



function SimpleScheduler(props) {

    const [appointmentList, setAppointmentList] = useState(props.appointmentList)
    const [selectedAppointmentId, setSelectedAppointmentId] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [timeOptionSelectHelper, setTimeOptionSelectHelper] = useState("0")


    // Set the date selected
    function setDate(value) {
        setSelectedDate(value)

        // Reset the time variables
        setTimeOptionSelectHelper("0")
        setSelectedAppointmentId(null)
        setSelectedTime(null)
    }

    // Set the time selected
    // Also set the id
    function setTime(value) {
        setTimeOptionSelectHelper(value)
        setSelectedTime(value)
        const id = appointmentList.days.find(day => day.date_text == selectedDate).appointments.find(appointment => appointment.start_time_text == value).mission_event_availability_id
        setSelectedAppointmentId(id)
    }


    // Function to get the time list.
    function getTimeList() {
        if (selectedDate) {
            return appointmentList.days.find(day => day.date_text == selectedDate).appointments.map(appointment => {
                const time = appointment.start_time_text
                return (
                    <option value={time} key={time}>{time}</option>
                )
            })
        }
    }
    
    // Get hte list of dates
    let dateList = appointmentList.days.map(day => {
        const date = day.date_text
        return (
            <option value={date} key={date}>{date}</option>
        )
    })
    // Get the list of times
    let timeList = getTimeList()


    function DateSelector() {
        return (
            <div></div>
        )
    }

    function BackButton() {
        if (props.reschedule) {
            return (
                <div className="my-2 px-0">
                    <div className="btn btn-sm btn-secondary" onClick={() => props.setShowReschedule(false)}>Back</div>
                </div>
            )
        } else {
            return <></>
        }

    }

    // Hack - instead of having viewport breaks in css, just do a single one here. 
    let viewportClassModifer = window.innerWidth < 1100 ? 'col-8' : "col-4"

    return (
        <div >
            <div className="row">
                <div className="scheduling-instructions mb-3">
                    Select a time slot for your mission.
                </div>
            </div>
            <div className="row my-3">
                <div className="col-4">
                    <div className="form-prompt-left">
                        Available dates:
                    </div>
                </div>
                <div className={viewportClassModifer}>
                    <div className="">
                        {/* <select className="form-control form-select form-select-med" id="date" name="date" defaultValue="Select a date"  onChange={e => setDate(e.target.value)}> */}
                        <select className="form-select custom-select" name="date" onChange={e => setDate(e.target.value)} id="date" aria-label="Date select" defaultValue="0">
                            <option disabled value="0">Select a date</option>
                            {dateList}
                        </select>
                    </div>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-4">
                    <div className="form-prompt-left">
                        Available times:
                    </div>
                </div>
                <div className={viewportClassModifer}>
                    <div className="">
                        <select className="form-select custom-select" name="time" value={timeOptionSelectHelper} onChange={e => setTime(e.target.value)} id="time" aria-label="Time select">
                            <option disabled value="0">Select a time</option>
                            {timeList}
                        </select>
                        <div className="timezone-note"> Timezone: {props.timezone} </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <div className="submit-button-container">
                        <AppointmentSubmitButton 
                            selectedDate={selectedDate} 
                            selectedTime={selectedTime} 
                            selectedAppointmentId={selectedAppointmentId} 
                            missionId={props.missionId}
                        />
                    </div>
                    {BackButton()}
                </div>
            </div>
        </div>
    )
}



export default SimpleScheduler

// appointmentList = { appointmentList }
// local = { props.local }
// onSelect = { selectNewAppointment }
// timezone = { props.timezone }