import React, { useEffect, useState, useContext } from "react";
import CardUpcomingInterviews from "../components/dashboard_components/CardUpcomingInterviews";
import CardPastInterviews from "../components/dashboard_components/CardPastInterviews";
import CardAccount from "../components/dashboard_components/CardAccount";

import { UserPortalContainer, AccountColumn } from "../components/LayoutComponents";
import HeaderUser from "../components/menu_components/HeaderUser";

function Dashboard(props) {

  return (
    <>
      <HeaderUser />
      <UserPortalContainer>
        <AccountColumn />
        <div className="col px-2">
          <CardUpcomingInterviews />
          <CardPastInterviews />
        </div>
      </UserPortalContainer>
    </>

  )

}


export default Dashboard;