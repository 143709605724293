import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useAlert } from 'react-alert';
import { Survey, Model, StylesManager } from "survey-react";
import DOMPurify from "dompurify";



//  Okay, so this has two pages. 
// The first page is the generic page that is used for all missions.
//      Explains what an autonomous mission is.
// The second page is the detailed instructions and the zoom link
//     Includes a button to get emailed the instructions. 
// Bottom of second page is a link to the closing survey. 


function ExplorationContent(props) {
    const [missionTitle, setMissionTitle] = useState("Loading...");
    const [missionReward, setMissionReward] = useState("{Loading...}");
    const [failedLoading, setFailedLoading] = useState(false);
    const [missionLoaded, setMissionLoaded] = useState(false);
    const [checkingCompletion, setCheckingCompletion] = useState(false);
    const [missionInstructions, setMissionInstructions] = useState("Loading...");
    const [pageOne, setPageOne] = useState(true);
    const [zoomLink, setZoomLink] = useState("Loading...");
    const [surveyId, setSurveyId] = useState(null);


    const navigate = useNavigate();
    const alert = useAlert();
    const exampleProp = useOutletContext();
    let params = useParams()
    const missionId = params.missionId;

    // Function that will call the api to check the length of the zoom recording. If it's long enough (more than 1 minute), then it will return true.
    async function submitAndGoToSurvey() {
        // Check the length of the zoom recording
        // If it's long enough, then go to the survey
        // If it's not long enough, then alert the user
        setCheckingCompletion(true)
        const zoomCompletion = await CheckZoomRecordingLengthApiCall(missionId)

        if (zoomCompletion.meeting_duration > 2) {
            // There is a recording, we can go to the survey.
            // Go to the survey
            console.log("Success! There is a recording. Going to the survey.")
            navigate(`mission/${missionId}/surveys/${surveyId}`)
        } else {
            // There is no recording, alert the user
            console.log("There is no recording. Alerting the user.")
            setCheckingCompletion(false)
            alert.show("You have not completed the mission while recording your screen", { type: 'error' })
        }
    }


    // Load the survey
    useEffect(() => {
        //Grab information on this user
        const GetMission = async () => {
            const response = await AutonomousMissionApiCall(missionId);

            if (response.error) {
                setFailedLoading(true);
                return;
            }
            const data = response.data;
            setMissionTitle(data.mission_title);
            setMissionReward(data.mission_reward);
            setMissionInstructions(data.mission_instructions);
            setSurveyId(data.survey_id);
            setMissionLoaded(true);
        }

        const GetZoom = async () => {
            const response = await GetInstantMissionApiCall(missionId);
            if (response.error) {
                setFailedLoading(true);
                return;
            }
            const data = response.data;
            setZoomLink(data.meeting_link);
        }

        GetMission();
        GetZoom();


    }, []);

    const autonomousPageSwitcher = pageOne ?
        <AutonomousMissionPageOne setPageOne={setPageOne} checkingCompletion={checkingCompletion} /> :
        <AutonomousMissionPageTwo setPageOne={setPageOne} instructions={missionInstructions} zoomLink={zoomLink} submitFunction={submitAndGoToSurvey} checkingCompletion={checkingCompletion} /> // Add in props here

    return (
        <div className="row mx-4 text-white">
            <div className="surveytitle px-4 py-4">
                <span style={{ color: "#858585" }} ></span> {missionLoaded ? missionTitle : <div>Loading...</div>}
                <div className="normal-font my-3">
                    This is an exploration mission. Upon completion, the reward of ${Math.round(missionReward)} will be added to your balance.
                </div>
            </div>
            {autonomousPageSwitcher}
        </div>
    )

}

function AutonomousMissionPageOne(props) {

    return (
        <div className="auton-page-body px-4 py-4 my-3">
            <div className="auton-page-text">
                <div className="mt-4 mb-4">This mission will ask you to complete a series of tasks while recording your screen. You'll be given a set of instructions and a link to a Zoom meeting on the next page.</div>
                <ol>
                    <li>Once you've read the instructions on the next page, join the Zoom meeting on your device.</li>
                    <li>Share your screen to the zoom meeting. </li>
                    <li>Complete the tasks as instructed.</li>
                    <li>When you are done, come back and fill out a short survey on your experience.</li>
                </ol>
            </div>
            <button className="btn btn-md btn-primary-color btn-shadow text-white no-outline px-3 my-3" onClick={() => props.setPageOne(false)}>Next</button>
        </div>
    )

}

function AutonomousMissionPageTwo(props) {

    return (
        <div className="auton-page-body px-4 py-4 my-3">
            <div className="auton-page-text">
                <p>Please complete the following tasks while sharing your screen on zoom. Then come back and complete the survey.</p>
                <div className="auton-page-subtitle mt-3">Instructions</div>
                <div className="mb-3 px-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.instructions) }} />
                <div className="auton-page-note px-4">
                    <p> These instructions have been emailed to you.</p>
                </div>
            </div>
            <div className="auton-page-subtitle mt-3">Zoom Link</div>
            <div className="auton-page-link px-4">
                <a className="auton-page-link" target="_blank" href={props.zoomLink}>{props.zoomLink}</a>
            </div>
            <div className="auton-page-note px-4">
                <p> Click this link on the device you'll use for this mission. Then share your screen. This zoom link will expire in two hours</p>
            </div>
            {/* Text link that goes back to the prior page */}
            <button className="btn btn-gray logout-button mx-2 px-4" onClick={() => props.setPageOne(true)}>Go back</button>

            <button className="btn btn-md btn-primary-color btn-shadow text-white no-outline px-3 my-3" onClick={props.submitFunction}>{!props.checkingCompletion ? "I'm done!" : "Loading..."}</button>

        </div>
    )

}


// This function is called when the survey is loaded and grabs the survey from the server
async function AutonomousMissionApiCall(missionId) {
    // Mock it up for now. 
    // return {
    //     mission_title: "Test Mission",
    //     mission_reward: 10,
    //     mission_instructions: "This is a test mission. Please do the following: <ol><li>Do this</li><li>Do that</li></ol>",
    //     survey_id: 1
    // }



    const url = "/api/v1/autonomousmissions/" + missionId;
    const token = document.querySelector('meta[name="csrf-token"]').content;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        if (response.ok) {
            const body = await response.json();
            body["error"] = false;
            return body
        } else {
            throw new Error("Network response was not ok.");
        }
    } catch (error) {
        console.log(error.message)
        return { error: true }
    }
}


async function GetInstantMissionApiCall(missionId) {
    // Mock it up for now. 
    // return {
    //     zoom_link: "https://zoom.us/j/123456789"
    // }

    const url = "/api/v1/autonomousmissions/" + missionId + '/getrecordinglink'
    const token = document.querySelector('meta[name="csrf-token"]').content;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        if (response.ok) {
            const body = await response.json();
            body["error"] = false;
            return body
        } else {
            throw new Error("Network response was not ok.");
        }
    } catch (error) {
        console.log(error.message)
        return { error: true }
    }
}

async function CheckZoomRecordingLengthApiCall(missionId) {
    const url = "/api/v1/autonomousmissions/" + missionId + '/checkrecording'
    const token = document.querySelector('meta[name="csrf-token"]').content;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        if (response.ok) {
            const body = await response.json();
            body["error"] = false;
            return body.data
        } else {
            throw new Error("Network response was not ok.");
        }
    } catch (error) {
        console.log(error.message)
        return { error: true }
    }
}

export default ExplorationContent