import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FormField, PrimaryButton, SecondaryButton, OrSeparater, LogInCard, ErrorMessage, NoticeMessage } from "../components/CommonComponents";

// Api Calls
import RegistrationAPI from "../api/api_calls/RegistrationAPI";

function SignUp(props) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [error, setError] = useState([]);
  const [notice, setNotice] = useState("");
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    if (location.state) {
      const tempNotice = location.state.notice || "";
      setNotice(tempNotice)
    }
  }, []);


  //Update state on input change
  function onChange(event) {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value)
      case 'password':
        setPassword(event.target.value)
      case 'passwordConfirmation':
        setPasswordConfirmation(event.target.value)
    }
  }


  // The log in function called on submit
  function onSubmit(event) {
    event.preventDefault();

    setError([]);
    setNotice("");

    if (email.length == 0 || password.length == 0) { return; }
    if (password != passwordConfirmation) { setError(["Passwords do not match"]); return; }
    if (password.length <= 7) { setError(["Password must be at least 8 characters long"]); return; }



    const SignUpUser = async () => {
      const result = await RegistrationAPI.create(email, password, passwordConfirmation, navigate);

      if (result.success) {

        // User is not yet signed in - they must confirm their email first.
        navigate(result.next_path)
      } else {
        // sets the message to display to user
        setError(result.error);
        setNotice("")
      }
    }


    SignUpUser()
  }



  return (
    <div className="container-fluid bg-black-color">
      <div className='row d-flex justify-content-center align-items-center h-100'>
        <ErrorMessage errors={error} />
        <NoticeMessage notices={notice} />
        <div className="bg-black-color full-page-background">
        </div>
        <div className="col12">
          <div className=' mw-440 px-1 my-80px mx-auto'>  {/* This sets the width and margins */}
            <LogInCard>
              <h2 className="signup-title-font text-center mb-2">Create Account</h2>

              <div className="card-body-2 px-2">
                <form onSubmit={(event) => onSubmit(event)} className="form bg-transparent-color no-outline text-white w-100 mt-5">
                  <FormField label="Email" name="email" id="userEmail" placeholder="Email" type="email" onChange={onChange} marginClass="mb-0" />
                  <div className="terms-agreement text-left mb-4 px-1">
                    We use your email to send you updates. We will not share it.
                  </div>
                  <FormField label="Password" name="password" id="userPassword" placeholder="Password" type="password" autoComplete="new-password" onChange={onChange} marginClass="mb-4" />
                  <FormField label="Confirm Password" name="passwordConfirmation" id="userPasswordConfirm" placeholder="Confirm Password" type="password" autoComplete="new-password" onChange={onChange} marginClass="mb-4" />

                  <div className="mt-3">
                    <div className="terms-agreement text-left mb-2 px-1">
                      By signing up, I agree to the Despark <a href="https://despark.io/legal/respondent-terms-of-service/" target="_blank">Respondent Terms of Service</a> and <a href="https://despark.io/legal/privacy-policy/" target="_blank">Privacy Policy</a>.
                    </div>
                    <PrimaryButton text="Create Account" buttonType="submit" marginClass="mb-3" />
                  </div>
                </form>
                <div className="bg-transparent-color">
                  <OrSeparater />
                  <div className="d-flex justify-content-center mt-32">
                    <SecondaryButton text="Sign up with wallet" buttonType="submit" onClick={() => navigate("/signup/wallet")} />
                  </div>
                </div>
              </div>
            </LogInCard>
            <div className="flex-column align-items-center pcx-30 my-32px w-100">
              <div className="sign-up-tertiary">Already have an account? <Link className="secondary-link" to={{ pathname: "/signin" }}>Log In</Link></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}


export default SignUp