import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {

    create: async (user_hash, crypto_usage_hash, user_demographics_hash, user_type_hash, log_hash = null, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "users/userprofile";


        const body = JSON.stringify({
            user: user_hash,
            crypto_usage: crypto_usage_hash,
            user_demographic: user_demographics_hash,
            user_type: user_type_hash,
            sign_in_log: log_hash
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    getCountries: async (handleRedirect = null) => {
        const url = apiConfig.apiUrl + "countries/";

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    userHasWallet: async (handleRedirect = null) => {
        const url = apiConfig.apiUrl + "userwallet/haswallet";

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    getUserTypes: async (handleRedirect = null) => {
        const url = apiConfig.apiUrl + "usertypes/";

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    }
}