import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingIcon from "../LoadingIcon";

// Api Calls
import ScheduledEventAPI from "../../api/api_calls/ScheduledEventAPI";


function CardPastInterviews(props) {
    const [pastEvents, setPastEvents] = useState([]);
    const [eventsLoaded, setEventsLoaded] = useState(false);
    const navigate = useNavigate();

    // Load the screener
    useEffect(() => {
        //Grab information on this user
        const GetPast = async () => {
            const data = await ScheduledEventAPI.pastEvents(navigate);

            if (data.success) {
                setEventsLoaded(true);
                setPastEvents(data);
            }
        }

        GetPast();
    }, []);

    return (
        <div className="card rounded-body half-screen-height no-outline bg-secondary-color  text-white my-3" >
            <div className="card-title no-outline px-1 py-1">
                <h3 className="card-header">
                    Past Interviews
                </h3>
            </div>
            <div className="card-body no-outline profile-style full-height px-1 py-1">
                <div className="row center-text">
                    {eventsLoaded ? <EventTable events={pastEvents} /> : <LoadingIcon />}

                </div>
            </div>
        </div>
    )
}

function EventTable(props) {

    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    const eventList = props.events;
    const eventListArray = eventList.map((event, index) => (
        <tr key={index}>
            <td className="align-middle px-3" style={{ border: "none" }}>
                <div className="day-text">
                    {(new Date(event.start_time)).toLocaleDateString('en-us', { weekday: "long" })},
                </div>
                <div>
                    {(new Date(event.start_time)).toLocaleDateString('en-us', { month: "long", day: "numeric" })}
                </div>
            </td>
            <td style={{ border: "none" }} className="align-middle  px-3">
                <div className="day-text">
                    {(new Date(event.start_time)).toLocaleTimeString('en-us', { hour: "numeric", minute: "numeric", hour12: true })}
                </div>
            </td>
            <td className="align-middle  px-3" style={{ border: "none" }}>
                <div>
                    {Intl.DateTimeFormat().resolvedOptions().timeZone}
                </div>
            </td>
            <td className="align-middle  px-3" style={{ border: "none" }}>
                <div>
                    {toTitleCase(event.status)}
                </div>
            </td>
            <td className="align-middle  px-3" style={{ border: "none" }}>
                {event.mission_user_list.mission.name}
            </td>
            <td className=" px-3" style={{ border: "none" }}>
                {<Link to={{ pathname: "/mission/" + event.mission_user_list.mission_id.toString() + "/completed/" }}
                    className="btn btn-primary-color btn-shadow text-white no-outline px-3 my-3">
                    View
                </Link>}
            </td>
        </tr>
    ));

    const emptyCard = (
        <div >
            No past interviews
        </div>
    )

    const fullCard = (
        <div className="table-wrapper table-responsive rounded-corners mx-4 mt-4 mb-3">
            <table className="table table-sm table-secondary-gray table-hover white-text">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Timezone</th>
                        <th scope="col">Status</th>
                        <th scope="col">Mission</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {eventListArray}
                </tbody>
            </table>
        </div>
    )
    return (
        <div>
            {eventListArray.length > 0 ? fullCard : emptyCard}
        </div>
    )
}


// // This function is called when the page is loaded 
// async function PastScheduleApiCall() {
//     const url = "/api/v1/pastevents/"
//     const token = document.querySelector('meta[name="csrf-token"]').content;


//     try {
//         const response = await fetch(url, {
//             method: "GET",
//             headers: {
//                 "X-CSRF-Token": token,
//                 "Content-Type": "application/json"
//             }
//         })
//         if (response.ok) {
//             const body = await response.json();

//             return body
//         } else {
//             throw new Error("Network response was not ok.");
//         }
//     } catch (error) {
//         console.log(error.message)
//         return { error: true }
//     }
// }
export default CardPastInterviews;