import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";

import ButtonSmallCTA from "./ButtonSmallCTA";

function CardAccount(props) {



    return (
        <div className="card rounded-body flex-grow-1 no-outline bg-secondary-color text-white my-3 px-1" >

            <div className="card-title no-outline  pt-1 mb-0">
                <h3 className="card-header">
                    Account
                </h3>
            </div>
            <div className="card-body no-outline profile-style d-flex full-height px-3 py-0 my-2">
                <div>
                    <AccountInfo email={props.email} extendedProfileComplete={props.demo_exists} />
                    <AccountDivider />
                    <div className="balances-container">
                        <BalanceInfo balance={props.balance} />
                        {props.bonusInterviewBalance > 0 || props.bonusSurveyBalance > 0 ?
                            <BonusBalanceCard bonusInterviewBalance={props.bonusInterviewBalance} bonusSurveyBalance={props.bonusSurveyBalance} />
                            : null}
                    </div>
                </div>
            </div>

        </div>
    )
}

function AccountInfo(props) {
    return (
        <div className="account-pane">
            <div className="account-pane-item my-2">
                <div className="account-header-font">
                    Email
                </div>
                <div className="account-pane-info">
                    {props.email}
                </div>
            </div>
            <div className="account-pane-item my-2">
                <div className="account-header-font">
                    Extended Profile
                </div>
                <div className="account-pane-info">
                    {props.extendedProfileComplete ? <CompleteExtendedProfileInfo /> : <NotCompleteExtendedProfileInfo />}
                </div>
            </div>
        </div>
    )
}

function AccountDivider(props) {
    return (
        <div className="account-divider">
        </div>
    )
}

function BalanceInfo(props) {
    return (
        <div className="account-pane ">
            <div className="account-header-font">
                Balance
            </div>
            <div className="d-flex justify-content-between align-items-center account-pane-info my-2">
                <div className="balance-text">
                    ${props.balance}
                </div>
                <div className="profile-spacer"></div>
                <Link to={{ pathname: "/cashouts", state: { fromDashboard: true } }}
                    className="text-link">
                    Cash Out
                </Link>
            </div>
        </div>
    )
}

function CompleteExtendedProfileInfo(props) {
    return (

        <div className="d-flex justify-content-between">
            <div className="">
                Complete
            </div>
            <div className="profile-spacer"></div>
            <Link to={{ pathname: "/profile", state: { fromDashboard: true } }}
                className="text-link">
                Edit
            </Link>
        </div>

    )
}

function NotCompleteExtendedProfileInfo(props) {
    return (

        <div className="d-flex justify-content-between">
            <div className="incomplete-profile-text">
                Incomplete
            </div>
            <div className="profile-spacer"></div>
            <div className="incomplete-text-size">
                <ButtonSmallCTA path="/profile" text="Edit" />
            </div>
        </div>

    )
}

function BonusBalanceCard(props) {

    return (
        <div className="bonus-balance-card">
            <div className="bonus-balance-title">
                You have a bonus waiting:
            </div>
            <div className="bonus-balance-rows-container">
                {props.bonusInterviewBalance > 0 ? <BonusBalanceRow balance={props.bonusInterviewBalance} description="when you complete an interview mission" /> : null}
                {props.bonusSurveyBalance > 0 ? <BonusBalanceRow balance={props.bonusSurveyBalance} description="when you complete a survey mission" /> : null}
            </div>
        </div>
    )
}

function BonusBalanceRow(props) {
    return (
        <div className="bonus-balance-row">
            <div className="bonus-balance">
                ${props.balance}
            </div>
            <div className="bonus-balance-description">
                {props.description}
            </div>
        </div>
    )
}

// function Placeholder() {
//     return (
//         <div className="flex-fill">
//             <div className="d-flex flex-column align-items-center justify-content-center row-gap-2 px-3 py-3">
//                 <div>
//                     <PulseLoader color="white" aria-label="Loading Spinner" size={12} />
//                 </div>
//                 <div className="loading-placeholder-text">
//                     Loading...
//                 </div>
//             </div>
//         </div>
//     )
// }

export default CardAccount;