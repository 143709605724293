import React, { useState, useEffect } from "react";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import CustomAlertTemplate from "./utility_components/CustomAlertTemplate";

import { RainbowKitProvider, getDefaultConfig, darkTheme } from '@rainbow-me/rainbowkit'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { configureChains, createConfig, WagmiProvider, http } from 'wagmi'; // Replacted wagMiConfig
import { mainnet, polygon, optimism, arbitrum } from 'wagmi/chains';
// import { alchemyProvider } from 'wagmi/providers/alchemy';
// import { publicProvider } from 'wagmi/providers/public';


import App from './App';

function AppWrapper(props) {

    // const { chains, publicClient } = configureChains(
    //     [mainnet, polygon, optimism, arbitrum],
    //     [
    //         alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
    //         publicProvider()
    //     ]
    // );

    /* getDefaultWallets is now optional */
    // const { connectors } = getDefaultWallets({
    //     appName: 'My RainbowKit App',
    //     projectId: "413d1857cc44483195205df495fb1477",
    //     chains
    // });

    // const wagmiConfig = createConfig({
    //     autoConnect: true,
    //     connectors,
    //     publicClient
    // })

    const wagmiConfig = getDefaultConfig({
        appName: 'Despark',
        projectId: '413d1857cc44483195205df495fb1477',
        chains: [mainnet, polygon, optimism, arbitrum],
        transports: {
            [mainnet.id]: http(),
        },
    })

    const queryClient = new QueryClient()

    // optional configuration
    const alertOptions = {
        // you can also just use 'bottom center'
        position: positions.BOTTOM_CENTER,
        timeout: 5000,
        offset: '30px',
        // you can also just use 'scale'
        transition: transitions.SCALE
    }

    return (
        <div className="app">
            <AlertProvider template={CustomAlertTemplate} {...alertOptions}>
                <WagmiProvider config={wagmiConfig}>
                    <QueryClientProvider client={queryClient}>
                        <RainbowKitProvider
                            theme={darkTheme({
                                accentColor: '#6339D2',
                                accentColorForeground: 'white',
                                borderRadius: 'large',
                                fontStack: 'system',
                                overlayBlur: 'small',
                            })}
                        >
                            <App />
                        </RainbowKitProvider>
                    </QueryClientProvider>
                </WagmiProvider>
            </AlertProvider>
        </div>
    )
}

export default AppWrapper;