import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";


function PostSignUp() {
    const navigate = useNavigate()

    useEffect(() => {
        navigate("/signin", { state: { notice: "Your account has been created. Please click 'forgot password' to set your password." } });
    }, []);

    return <></>
};

export default PostSignUp;