import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {
    getPublicScheduleStatus: async (missionId, timezone = "", handleRedirect = null) => {
        const timeZoneString = timezone != "" ? "?timezone=" + timezone : ""
        const url = apiConfig.apiUrl + missionId + "/schedule" + timeZoneString;

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    cancelUpcomingEvent: async (missionId, handleRedirect = null) => {
        const url = apiConfig.apiUrl + missionId + "/schedule/cancel";

        try {
            const response = await fetch(url, {
                method: "PUT",
                headers: addCommonHeaders(),
                credentials: 'include',
            })
            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    submitAppointment: async (missionId, missionEventAvailabilityId, email, name, handleRedirect = null) => {
        const url = apiConfig.apiUrl + missionId + "/schedule"


        const body = JSON.stringify({
            mission_event_availability_id: missionEventAvailabilityId,
            email: email,
            name: name

        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })
            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },
}