import React, {useState, useEffect} from "react";
import PropagateLoader from "react-spinners/PropagateLoader";


function Placeholder() {
    return ( 
        <div className="no-outline profile-style d-flex full-height align-items-center justify-content-center px-1 py-1">
            <div className="">
                <PropagateLoader color="white" aria-label="Loading Spinner" />
            </div>
        </div>
    )
}

export default Placeholder;