import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Placeholder from "../menu_components/Placeholder";
import CashOutHistory from "./CashOutHistory";
import CashOutNew from "./CashOutNew";


function ContentCashOut(props) {
    return (
        <div>
            {props.activeTab == "history" ?
                <CashOutHistory cashOutHistory={props.cashOutHistory} /> :
                <CashOutNew balance={props.balance} />}
        </div>

    )
}

export default ContentCashOut;