import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FormField, PrimaryButton, SecondaryButton, OrSeparater, LogInCard, ErrorMessage, NoticeMessage } from "../components/CommonComponents";
import { ConnectButton } from '@rainbow-me/rainbowkit';

import { useSignMessage, useAccount } from "wagmi";

// Api Calls
import RegistrationAPI from "../api/api_calls/RegistrationAPI";




function SignUpPostWalletCredentialCollection(props) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [error, setError] = useState([]);
  const [notice, setNotice] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const { address } = useAccount();
  const [isConnected, setIsConnected] = useState(false);
  const [messageToSign, setMessageToSign] = useState("Despark is having a server error. Signing this is harmless, but won't log you in. Please try again later.");

  const passwordToolTip = "Set a password so that you can log in by wallet signature or email and password."
  const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage()


  // --------------------------------------------
  // --------------------------------------------
  // --------------UseEffects------------------
  // --------------------------------------------
  // --------------------------------------------

  // If they disconnect, send them back to the start
  useEffect(() => {
    if (!address) {
      navigate("/signup/wallet", { state: { notice: "Please connect your wallet." } });
    }
  }, [address])


  // On load, check if their wallet is connected, get the nonce, and enable tooltips
  useEffect(() => {
    if (location.state) {
      const tempNotice = location.state.notice || "";
      setNotice(tempNotice)
    }

    setIsConnected(!!address);
    setError("");

    if (!address) {
      navigate("/signup/wallet", { state: { notice: "Please connect your wallet." } });
    }

    const GetNonce = async (userWallet) => {
      const result = await RegistrationAPI.getNonce(userWallet, navigate);

      if (result.success) {
        // Update the message to have the correct nonce.

        setMessageToSign(result.signature_message)
      } else {
        // Display an error message
        setError("Something went wrong. Please try again later or sign up using email. ")
      }
    }

    // Fetch the signature message
    GetNonce(address)

    // Enable tooltips
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
  }, []);

  // Once the signature is returned, we need to send it to the backend.
  useEffect(() => {

    // Function to call the async signup function.
    const SignUpUser = async () => {
      const result = await RegistrationAPI.createWithWallet(email, password, passwordConfirmation, address, data, navigate);

      if (result.success) {
        // Redirect to the second part of onboarding flow
        navigate(result.next_path)
      } else {
        // sets the message to display to user
        setError(result.error);
        setNotice("")
      }
    }

    // If the signature is successful, submit the signature and everything else to the backend.
    if (isSuccess) {
      // Submit the signature and everything else to the backend.
      SignUpUser()
    }

  }, [isSuccess]);

  // --------------------------------------------
  // --------------------------------------------
  // -------------- HELPERS ------------------
  // --------------------------------------------
  // --------------------------------------------

  //Update state on input change
  function onChange(event) {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value)
      case 'password':
        setPassword(event.target.value)
      case 'passwordConfirmation':
        setPasswordConfirmation(event.target.value)
    }
  }


  // The log in function called on submit
  function signAndSubmit(event) {
    event.preventDefault();

    setError([]);
    setNotice("");

    if (email.length == 0 || password.length == 0) { return; }
    if (password != passwordConfirmation) { setError(["Passwords do not match"]); return; }
    if (password.length <= 7) { setError(["Password must be at least 8 characters long"]); return; }

    //First, ask them to sign. 
    signMessage({ message: messageToSign });


    //Then, we need to wait for the sign message to come back.
    //Then, we need to send the sign message to the backend along with the submission info. 
    // Backend will then determine if the signature is valid.
    // And update the account accordingly if so.


  }

  function ButtonOrLoadingMessage() {
    if (isLoading) {
      return (
        <SecondaryButton text="Waiting for your wallet signature..." buttonType="button" />
      )
    } else {
      return (
        <PrimaryButton text="Sign & Submit" buttonType="submit" marginClass="mb-3" />
      )
    }
  }

  // --------------------------------------------
  // --------------------------------------------
  // -------------- RETURN ------------------
  // --------------------------------------------
  // --------------------------------------------

  return (
    <div className="container-fluid bg-black-color">
      <div className='row d-flex justify-content-center align-items-center h-100'>
        <ErrorMessage errors={error} />
        <NoticeMessage notices={notice} />
        <div className="bg-black-color full-page-background">
        </div>
        <div className="col12">
          <div className=' mw-440  my-80px mx-auto'>  {/* This sets the width and margins */}
            <LogInCard>
              <h2 className="signup-title-font text-center mb-2">Account Setup</h2>
              <div className="card-title-note text-center">
                Wallet connected! Let's set up your account.
                <br />
                What email should we use to contact you?
                <br />
                We will not share it.
              </div>
              <div className="card-body-2 px-2">
                <form onSubmit={(event) => signAndSubmit(event)} className="form bg-transparent-color no-outline text-white w-100 mt-5">
                  <FormField label="Email" name="email" id="userEmail" placeholder="Email" type="email" onChange={onChange} marginClass="mb-4" />
                  <FormField label="Password" name="password" id="userPassword" placeholder="Password" type="password" autoComplete="new-password" onChange={onChange} tooltip={passwordToolTip} marginClass="mb-4" />
                  <FormField label="Confirm Password" name="passwordConfirmation" id="userPasswordConfirm" placeholder="Confirm Password" type="password" autoComplete="new-password" onChange={onChange} tooltip={passwordToolTip} marginClass="mb-4" />
                  <div className="mt-32">
                    <ButtonOrLoadingMessage />
                  </div>
                </form>

              </div>
            </LogInCard>
            <div className="flex-column align-items-center pcx-30 my-32px w-100">
              <div className="sign-up-tertiary">Already have an account? <Link className="secondary-link" to={{ pathname: "/signin" }}>Log In</Link></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}


export default SignUpPostWalletCredentialCollection