import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {
    index: async (handleRedirect = null) => {
        const url = apiConfig.apiUrl + "cashouts/";

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    create: async (eth_address, usd_amount, blockchain, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "cashouts/";

        const body = JSON.stringify({
            cash_out: {
                eth_address,
                usd_amount,
                blockchain
            }
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    destroy: async (cashOutId, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "cashouts/" + cashOutId;

        const body = JSON.stringify({ cash_out: { id: cashOutId } });

        try {
            const response = await fetch(url, {
                method: "DELETE",
                headers: addCommonHeaders(),
                body: body,
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },



}