import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAlert } from 'react-alert';
import { FormField, PrimaryButton, LogInCard, ErrorMessage, NoticeMessage, PreAuthPageContainer, TurnBackgroundBlackComponent, LogInContainer, LogInCardTitle, LogInCardBody, LogInForm, SignUpTertiary } from "../components/CommonComponents"

// API Calls
import PasswordAPI from "../api/api_calls/PasswordAPI";


function ResetPassword(props) {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");
  const [notice, setNotice] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();


  const navigate = useNavigate();


  const alert = useAlert();

  function onChange(event) {
    if (event.target.name == "password") {
      setPassword(event.target.value);
    }
    if (event.target.name == "passwordConfirmation") {
      setPasswordConfirmation(event.target.value);
    }
  }

  function onSubmit(event) {
    event.preventDefault();


    if (password != passwordConfirmation) {
      alert.show("Passwords don't match", { type: 'error' })
      return;
    }

    if (password.length <= 7) {
      alert.show("Password must be at least 8 characters long", { type: 'error' })
      return;
    }

    const reset_password_token = searchParams.get("reset_password_token");

    const resetPassword = async () => {
      const data = await PasswordAPI.update(password, passwordConfirmation, reset_password_token);

      if (data.success) {
        setError("")
        setNotice("")
        // New plan - send to primary login page, and have a message that says "Your password has been reset. Please log in."
        navigate("/signin", { state: { notice: "Your password has been reset. Please log in." } });

      } else {
        // sets the message to display to user
        setError(data.error);
        setNotice("")
      }

    }

    resetPassword();
  }



  return (
    <PreAuthPageContainer>
      <ErrorMessage errors={error} />
      <NoticeMessage notices={notice} />
      <TurnBackgroundBlackComponent />

      <LogInContainer >
        <LogInCard>
          <LogInCardTitle title="Change your Password" />
          <LogInCardBody >
            <LogInForm onSubmit={(event) => onSubmit(event)} >
              <FormField label="Password" name="password" id="userPassword" placeholder="Password" type="password" autoComplete="new-password" onChange={onChange} marginClass="mb-4" />
              <FormField label="Confirm Password" name="passwordConfirmation" id="userPasswordConfirm" placeholder="Confirm Password" type="password" autoComplete="new-password" onChange={onChange} marginClass="mb-4" />
              <div className="height-40">
              </div>
              <div className="mt-32">
                <PrimaryButton text="Submit" buttonType="submit" marginClass="mb-3" />
              </div>
            </LogInForm>
          </LogInCardBody>
        </LogInCard>
        <SignUpTertiary preLinkText="Sudden flash of memory? " linkText="Login" linkPath="/signin" />
      </LogInContainer>
    </PreAuthPageContainer>


  );

}


// async function ResetPasswordApiCall(body) {
//   const url = "/users/password";
//   const token = document.querySelector('meta[name="csrf-token"]').content;

//   try {
//     const myResponse = await fetch(url, {
//       method: "PUT",
//       headers: {
//         "X-CSRF-Token": token,
//         "Content-Type": "application/json"
//       },
//       body: body
//     })

//     if (myResponse.ok) {
//       const returnData = {
//         success: true,
//         failureReason: ""
//       }
//       return returnData

//     } else if (myResponse.status == 422) {
//       const failureReason = "Reset password token is invalid. Please request a new password reset email."
//       const returnData = {
//         success: false,
//         failureReason: failureReason
//       }
//       return returnData
//     } else {
//       console.log("Damn")
//       throw new Error("Network response was not ok.");
//     }

//   } catch (error) {
//     const returnData = {
//       success: false,
//       failureReason: "Unable to complete request. Please try again later. If this issue persists, contact us at info@despark.io."
//     }
//     return returnData
//   }
// }



//export default ResetPassword;
export default ResetPassword;