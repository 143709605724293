import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingIcon from "../LoadingIcon";

import MissionCard from "./MissionCard";

// Api Calls
import MissionsAPI from "../../api/api_calls/MissionAPI";


function MissionsListCard(props) {
  const navigate = useNavigate();

  const [availableMissions, setAvailableMissions] = useState({});
  const [completedMissions, setCompletedMissions] = useState({});
  const [rejectedMissions, setRejectedMissions] = useState({});
  const [loadMissionsComplete, setLoadMissionsComplete] = useState(false);
  const [activeTab, setActiveTab] = useState("available");



  // --------------------------------------------
  // --------------------------------------------
  // --------------UseEffects------------------
  // --------------------------------------------
  // --------------------------------------------


  useEffect(() => {
    // Grab information on this user

    async function getMissions() {
      const result = await MissionsAPI.index(navigate);

      if (result.success) {
        setAvailableMissions(result.available_missions);
        setCompletedMissions(result.completed_missions);
        setRejectedMissions(result.rejected_missions);
        setLoadMissionsComplete(true);

      } else {
        setLoadMissionsComplete(false);
      }

    }

    getMissions();
  }, []);

  // --------------------------------------------
  // --------------------------------------------
  // --------------Helper Functions------------------
  // --------------------------------------------
  // --------------------------------------------

  function updateTab(tab) {
    setActiveTab(tab)
  }

  // --------------------------------------------
  // --------------------------------------------
  // --------------Render------------------
  // --------------------------------------------
  // --------------------------------------------


  const activeClass = "nav-link active card-nav text-white";
  const inactiveClass = "nav-link card-nav text-white";

  return (
    <div className="card rounded-body bg-secondary-color outline-secondary  text-white my-3" >
      <div className="card-title no-outline px-1 py-1">
        <h3 className="card-header">
          Missions
        </h3>
      </div>
      <div className="card-body no-outline profile-style  px-1 py-1">
        <ul className="nav nav-tabs no-bottom-border mx-2 mb-3">
          <li className="nav-item">
            <div className={activeTab == "available" ? activeClass : inactiveClass}
              onClick={() => updateTab("available")}>
              Available
            </div>
          </li>
          <li className="nav-item">
            <div className={activeTab == "rejected" ? activeClass : inactiveClass}
              onClick={() => updateTab("rejected")}>
              Rejected
            </div>
          </li>
          <li className="nav-item">
            <div className={activeTab == "completed" ? activeClass : inactiveClass}
              onClick={() => updateTab("completed")}>
              Completed
            </div>

          </li>
        </ul>
      </div>
      {/* THIS IS WHERE AVAILABLE IS SET */}
      {!loadMissionsComplete ? <LoadingIcon /> :
        <MissionCardChoice
          available={availableMissions}
          rejected={rejectedMissions}
          completed={completedMissions}
          activeTab={activeTab}
          extendedProfileCompleteBool={props.extendedProfileCompleteBool}
        />}
    </div>
  )
}



function MissionCardChoice(props) {
  let currentMissions = props.activeTab == "available" ? props.available : (props.activeTab == "completed" ? props.completed : props.rejected);

  if (currentMissions.length == 0) {
    return (
      props.activeTab == "available" ? availableText(props) : nonAvailableText(props)
    )
  } else {
    return (
      <div>
        {currentMissions.map((missionWrapper) => {
          return (
            <MissionCard
              key={missionWrapper.mission.id}
              mission_name={missionWrapper.mission.name}
              audience_description={missionWrapper.mission.audience_description}
              format={missionWrapper.mission_format}
              reward_amount_usd={missionWrapper.mission.reward_amount_usd}
              number_of_spots={missionWrapper.num_remaining_spots}
              mission_id={missionWrapper.mission.id}
              user_status={missionWrapper.mission_user_status}
              mission_type={missionWrapper.mission_is_survey ? "survey" : (missionWrapper.mission_is_exploration ? "exploration" : "interview")}
              mission_locked={missionWrapper.mission_locked}
              locked_wallet_requirements={missionWrapper.locked_wallet_requirements}
            />
          )
        })}
      </div>
    )
  }
}

function nonAvailableText(props) {
  return (
    <div className="card-body no-outline profile-style d-flex full-height align-items-center justify-content-center px-1 py-1">
      <p className="text-center">You have no {props.activeTab} missions</p>
    </div>
  )
}

function availableText(props) {
  const endingText = props.extendedProfileCompleteBool ? "Check back again soon!" : "Complete your extended profile to match with more missions. Check back again soon!"
  return (
    <div className="card-body no-outline profile-style d-flex full-height align-items-center justify-content-center px-1 py-1">
      <p className="text-center">You have no {props.activeTab} missions. {endingText}</p>
    </div>
  )
}


export default MissionsListCard;
