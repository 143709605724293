import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {
    pastEvents: async (handleRedirect = null) => {
        const url = apiConfig.apiUrl + "scheduled_events/past_events";

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    futureEvents: async (handleRedirect = null) => {
        const url = apiConfig.apiUrl + "scheduled_events/future_events/";

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    cancel: async (scheduledEventId, handleRedirect = null) => {
        const url = apiConfig.apiUrl + "scheduled_events/" + scheduledEventId + "/cancel";;

        try {
            const response = await fetch(url, {
                method: "PUT",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },
}