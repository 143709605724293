import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { disconnect } from '@wagmi/core'
import { useAlert } from 'react-alert';
import { UserContext } from "../../../UserContext";

// API Calls  
import SessionAPI from "../../../preauth_portal/api/api_calls/sessionAPI"



{/* <form class="button_to" method="post" action="/users/sign_out">
<input type="hidden" name="_method" value="delete" autocomplete="off">
<button type="submit">Log Out</button>
<input type="hidden" name="authenticity_token" value="6m6pT0-W93elajFMnSbmnxFMxrg-iRwanjRwTBMu3dhNHzK0HTRQ2MyZd6g0fpSBgVsjULiKGc0gasZ2OOZgoA" autocomplete="off">
</form> */}


function LogoutButton(props) {
  const navigate = useNavigate();
  const alert = useAlert();
  const { user, setUser, refreshUser } = useContext(UserContext);


  async function disconnectWallet() {
    await disconnect()
  }

  async function logout(event) {
    event.preventDefault();

    const response = await SessionAPI.destroy();

    if (response.success) {
      disconnectWallet()
      refreshUser()

      navigate("/signin")
    } else {
      alert.show("Unable to log out, please contact info@despark.io", { type: 'error' })
    }
  }


  return (
    // <form className="button_to" method="post" action="/users/signout">
    // <input type="hidden" name="_method" value="delete" autoComplete="off" />
    <button type="submit" onClick={logout} className='btn btn-gray logout-button mx-2 px-4'>Log Out</button>
    // </form>
  )
}

export default LogoutButton
