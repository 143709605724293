import React, { useState, useEffect } from "react";
import { LogInCard, FormField, PrimaryButton } from "../../../preauth_portal/components/CommonComponents";
import { useAlert } from 'react-alert';
import SignUpApiCall from "../../api/api_calls/SignUpApiCall";


function PublicSignUpCard(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const alert = useAlert();



    // ----------------------------------------
    // ----------------------------------------
    // --------------- UseEffects ----------------
    // ----------------------------------------
    // ----------------------------------------



    // ----------------------------------------
    // ----------------------------------------
    // --------------- Helpers ----------------
    // ----------------------------------------
    // ----------------------------------------

    //Update state on input change
    function onChange(event) {
        switch (event.target.name) {
            case 'email':
                setEmail(event.target.value)
            case 'password':
                setPassword(event.target.value)
            case 'passwordConfirmation':
                setPasswordConfirmation(event.target.value)
        }
    }

    // The log in function called on submit
    function onSubmit(event) {
        event.preventDefault();
        const url = "/users/signupnew";



        if (email.length == 0 || password.length == 0) { return; }
        if (password != passwordConfirmation) { alert.show("Passwords do not match", { type: 'error' }); return; }
        if (password.length <= 7) { alert.show("Password must be at least 8 characters long", { type: 'error' }); return; }

        // You must do it this way, with the user on the outside. 
        const body = JSON.stringify({
            user: {
                email: email,
                password: password,
                password_confirmation: passwordConfirmation
            }
        });

        const SignUpUser = async (body) => {
            const result = await SignUpApiCall(body);

            if (result.success) {

                //We no longer set this, as they aren't logged in under confirmable.
                // props.setUserEmail && props.setUserEmail(result.userEmail); 
                // localStorage.removeItem("user");
                // localStorage.setItem("user", JSON.stringify(result.userEmail));
                // We use the javascript here to force it reload.
                // We pass through a rails route to force cSRF token to reload.
                window.location.href = result.next_path
            } else {
                // sets the message to display to user
                result.failureReasonArray.forEach((reason) => {
                    alert.show(reason, { type: 'error' });
                })
            }
        }


        SignUpUser(body)
    }

    return (
        <div className="col12">
            <div className=' mw-440 mx-auto'>  {/* This sets the width and margins */}
                <LogInCard borderClass="border-medium-gray">
                    <h5 className="text-center mb-2">Give Feedback, Get Paid </h5>
                    <div className="card-title-note text-center">
                        Enjoy giving feedback? Create a Despark account and you can get paid to help improve web3
                    </div>

                    <div className="card-body-2 px-2">
                        <form onSubmit={(event) => onSubmit(event)} className="form bg-transparent-color no-outline text-white w-100 mt-5">
                            <FormField label="Email" name="email" id="userEmail" placeholder="Email" type="email" onChange={onChange} marginClass="mb-4" />
                            <FormField label="Password" name="password" id="userPassword" placeholder="Password" type="password" autoComplete="new-password" onChange={onChange} marginClass="mb-4" />
                            <FormField label="Confirm Password" name="passwordConfirmation" id="userPasswordConfirm" placeholder="Confirm Password" type="password" autoComplete="new-password" onChange={onChange} marginClass="mb-4" />
                            <div className="mt-32">
                                <PrimaryButton text="Create Account" buttonType="submit" marginClass="mb-3" />
                            </div>
                        </form>
                    </div>
                </LogInCard>
            </div>
        </div>
    )

}

export default PublicSignUpCard;