import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingIcon from "../LoadingIcon";

// API Calls
import ScheduledEventAPI from "../../api/api_calls/ScheduledEventAPI";



function CardUpcomingInterviews(props) {
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [eventsLoaded, setEventsLoaded] = useState(false);
    const navigate = useNavigate();

    // Load the screener
    useEffect(() => {
        //Grab information on this user
        const GetUpcoming = async () => {
            const data = await ScheduledEventAPI.futureEvents(navigate);

            if (data.success) {
                setEventsLoaded(true);
                setUpcomingEvents(data);
            }
        }

        GetUpcoming();
    }, []);

    return (
        <div className="card rounded-body half-screen-height no-outline bg-secondary-color  text-white my-3" >
            <div className="card-title no-outline px-1 py-1">
                <h3 className="card-header">
                    Upcoming Interviews
                </h3>
            </div>
            <div className="card-body no-outline profile-style d-flex full-height align-items-center justify-content-center px-1 py-1">
                <div className="center-text">
                    {eventsLoaded ? <EventTable events={upcomingEvents} /> : <LoadingIcon />}

                    <br />
                    <Link to={{ pathname: "/missions" }}
                        className="btn btn-primary-color btn-shadow text-white no-outline px-3 my-3">
                        Explore Missions
                    </Link>
                </div>
            </div>
        </div>
    )
}

function EventTable(props) {
    const eventList = props.events;
    const eventListArray = eventList.map((event, index) => (
        <tr key={index}>
            <td className="align-middle px-3" style={{ border: "none" }}>
                <div className="day-text">
                    {(new Date(event.start_time)).toLocaleDateString('en-us', { weekday: "long" })},
                </div>
                <div>
                    {(new Date(event.start_time)).toLocaleDateString('en-us', { month: "long", day: "numeric" })}
                </div>
            </td>
            <td style={{ border: "none" }} className="align-middle  px-3">
                <div className="day-text">
                    {(new Date(event.start_time)).toLocaleTimeString('en-us', { hour: "numeric", minute: "numeric", hour12: true })}
                </div>
            </td>
            <td className="align-middle  px-3" style={{ border: "none" }}>
                <div>
                    {Intl.DateTimeFormat().resolvedOptions().timeZone}
                </div>
            </td>
            <td className="align-middle  px-3" style={{ border: "none" }}>
                {event.mission_user_list.mission.name}
            </td>
            <td className=" px-3" style={{ border: "none" }}>
                {<Link to={{ pathname: "/mission/" + event.mission_user_list.mission_id.toString() + "/schedule/" }}
                    className="btn btn-primary-color btn-shadow text-white no-outline px-3 my-3">
                    View
                </Link>}
            </td>
        </tr>
    ));

    const emptyCard = (
        <div >
            No scheduled interviews yet
        </div>
    )

    const fullCard = (
        <div className="table-wrapper table-responsive rounded-corners mx-4 mt-4 mb-3">
            <table className="table table-sm table-secondary-gray table-hover white-text">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Timezone</th>
                        <th scope="col">Mission</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {eventListArray}
                </tbody>
            </table>
        </div>
    )
    return (
        <div>
            {eventListArray.length > 0 ? fullCard : emptyCard}
        </div>
    )
}


// // This function is called when the page is loaded 
// async function ScheduleApiCall() {
//     const url = "/api/v1/scheduledevents/"
//     const token = document.querySelector('meta[name="csrf-token"]').content;



//     try {
//         const response = await fetch(url, {
//             method: "GET",
//             headers: {
//                 "X-CSRF-Token": token,
//                 "Content-Type": "application/json"
//             }
//         })
//         if (response.ok) {
//             const body = await response.json();

//             return body
//         } else {
//             throw new Error("Network response was not ok.");
//         }
//     } catch (error) {
//         console.log(error.message)
//         return { error: true }
//     }
// }
export default CardUpcomingInterviews;