import { apiConfig } from '../apiConfig';
import { addCommonHeaders, handleResponse, handleErrors } from '../apiUtils';

export default {
    index: async (handleRedirect = null) => {
        const url = apiConfig.apiUrl + "user_wallets/";

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    destroy: async ({ walletId, handleRedirect = null }) => {
        const url = apiConfig.apiUrl + "user_wallets/" + walletId.toString();

        try {
            const response = await fetch(url, {
                method: "DELETE",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },
    create: async ({ address, handleRedirect = null }) => {
        const url = apiConfig.apiUrl + "user_wallets/";

        const body = JSON.stringify({
            user_wallet: {
                address: address,
            }
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                credentials: 'include',
                body: body,
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    verify: async ({ walletId, address, signature_data, handleRedirect = null }) => {
        const url = apiConfig.apiUrl + "user_wallets/" + walletId + "/verify/";

        const body = JSON.stringify({
            signature: {
                signature_data: signature_data,
                address: address
            },
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: addCommonHeaders(),
                credentials: 'include',
                body: body,
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    },

    getNonce: async ({ walletId, handleRedirect = null }) => {
        const url = apiConfig.apiUrl + "user_wallets/" + walletId + "/get_nonce/";

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: addCommonHeaders(),
                credentials: 'include',
            })

            return await handleResponse(response, handleRedirect);

        } catch (error) {
            return handleErrors(error);
        }
    }

}