import React, { useState } from "react";
import { DropdownQuestion, DropdownOption, PrimaryButtonSmall } from "../../../preauth_portal/components/CommonComponents";



function DateTimeSelects(props) {
    const [appointmentList, setAppointmentList] = useState(props.appointmentList)
    const [timeOptionSelectHelper, setTimeOptionSelectHelper] = useState("0")

    const [selectedAppointmentId, setSelectedAppointmentId] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)

    // ----------------------------------------
    // Date and Time Set Up
    // ----------------------------------------

    function finalizeAppointment() {
        props.onSelect(selectedAppointmentId, selectedDate, selectedTime)
    }

    // Set the date selected
    function setDate(value) {
        setSelectedDate(value)

        // Reset the time variables
        setTimeOptionSelectHelper("0")
        setSelectedAppointmentId(null)
        setSelectedTime(null)
    }

    // Set the time selected
    // Also set the id
    function setTime(value) {
        setTimeOptionSelectHelper(value)
        setSelectedTime(value)
        const id = appointmentList.days.find(day => day.date_text == selectedDate).appointments.find(appointment => appointment.start_time_text == value).mission_event_availability_id
        setSelectedAppointmentId(id)
    }

    // Function to get the time list.
    function getTimeList() {
        if (selectedDate) {
            return appointmentList.days.find(day => day.date_text == selectedDate).appointments.map(appointment => {
                const time = appointment.start_time_text
                return (
                    <option value={time} key={time}>{time}</option>
                )
            })
        }
    }

    // Get hte list of dates
    let dateList = appointmentList.days.map(day => {
        const date = day.date_text
        return (
            <DropdownOption label={date} value={date} key={date} />
        )
    })

    let timeList = getTimeList()

    const buttonText = props.submitting ? "Loading..." : (props.rescheduleClicked ? "Submit" : "Next")

    function BackButton() {
        if (props.rescheduleClicked) {
            return (
                <div className="">
                    <a className="text-link-new" onClick={() => props.cancelReschedule()}>
                        Cancel
                    </a>
                </div>
            )
        } else {
            return (<></>)
        }
    }
    // ----------------------------------------
    // ----------------------------------------
    // ------------ Rendering ----------------
    // ----------------------------------------
    // ----------------------------------------


    return (
        <>
            <div className="d-flex flex-column row-gap-4 py-4 ">
                <div className="row align-items-center px-sm-3">
                    <div className="col-4 col-sm-3">
                        <div className="body-3">
                            Timezone:
                        </div>
                    </div>
                    <div className="col-8 col-sm-6 col-lg-4 ">
                        <div className="body-3 color-suva-gray px-2 px-sm-0">
                            {props.timezone}
                        </div>
                    </div>
                </div>
                <div className="row align-items-center px-sm-3">
                    <div className="col-4 col-sm-3">
                        <div className="body-3">
                            Available Days:
                        </div>
                    </div>
                    <div className="col-8 col-sm-6 col-lg-4 body-3">
                        <DropdownQuestion name="date" id="selectDate" onChange={setDate} placeholder="Select a date" defaultValue="-1">
                            {dateList}
                        </DropdownQuestion>
                    </div>
                </div>
                <div className="row align-items-center px-sm-3">
                    <div className="col-4 col-sm-3">
                        <div className="body-3">
                            Available Times:
                        </div>
                    </div>
                    <div className="col-8 col-sm-6 col-lg-4 body-3">
                        <DropdownQuestion name="time" id="time" value={timeOptionSelectHelper} onChange={setTime} ariaLabel="Time select">
                            <DropdownOption label="Select a time" value="0" key="time-0" isDisabled={true} />
                            {timeList}
                        </DropdownQuestion>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row align-items-center column-gap-4">
                <div className="">
                    <PrimaryButtonSmall text={buttonText} buttonType="button" onClick={() => finalizeAppointment()} isDisabled={selectedTime == null || props.submitting} />
                </div>
                <BackButton />
            </div>

        </>
    )
}

export default DateTimeSelects;