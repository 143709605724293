import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from 'react-alert';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { MissionDetailsHighlight, MissionDetailsConnectAndVerifyTextAndTooltip, VertSpacer } from "../../overview_components/CommonOverviewComponents";
import WalletRequirements from "./WalletRequirements";
import { useSignMessage, useAccount } from "wagmi";
import { PrimaryButtonSmall, SecondaryButtonSmall } from "../../../preauth_portal/components/CommonComponents";

// API Calls
import SessionAPI from "../../api/api_calls/SessionAPI";
import PublicMissionAPI from "../../api/api_calls/PublicMissionAPI";





function PMOverview(props) {
    let params = useParams()
    const navigate = useNavigate();
    const alert = useAlert();
    const missionId = params.missionId;
    const { address } = useAccount();
    const [isConnected, setIsConnected] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [meetsRequirements, setMeetsRequirements] = useState(false);
    const [requirementsLoading, setRequirementsLoading] = useState(false)
    const [showNextButton, setShowNextButton] = useState(false)

    const [messageToSign, setMessageToSign] = useState("Despark is having a server error. Signing this is harmless, but won't log you in. Please try again later.");
    const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage();
    const mockedRequirements = [
        {
            status: null,
            text: "Wallet has used Uniswap on Ethereum"
        },
        {
            status: null,
            text: "Wallet has balance of at least 0.1 ETH"
        },
    ]


    // ----------------------------------------
    // ----------------------------------------
    // --------------- UseEffects ----------------
    // ----------------------------------------
    // ----------------------------------------

    // Initialize tooltips  
    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
    }, []);

    // Set isConnected & get signature message
    useEffect(() => {
        setIsConnected(!!address);

        async function GetMessage(address) {
            const message = await SessionAPI.getSignatureMessage(address, missionId, navigate)
            if (message.success) {
                setMessageToSign(message.signature_message)
            } else {
                console.log("Error getting signature message")
            }
        }

        if (address) {
            GetMessage(address)
            // This is where we will start the address prep.
        }
    }, [address]);

    // Check Signature validity and whether the wallet meets teh requirements
    useEffect(() => {

        async function CreateSession() {
            const session = await SessionAPI.createSession(address, data, missionId, navigate)
            if (session.success) {
                // This is where the new checkwalletrequirements will go.
                setLoggedIn(true)
            } else {
                setLoggedIn(false)
                console.log(session.error)
                alert.show(session.error, { type: 'error' })
            }
        }

        async function CheckWalletRequirements() {
            const response = await PublicMissionAPI.checkWalletRequirements(address, missionId, navigate)
            if (response.success) {
                props.setWalletRequirements(response.wallet_requirements)
                setMeetsRequirements(response.meets_requirements)
            } else {
                console.log(response.error)
            }
            setRequirementsLoading(false)
        }

        // So, if we signed the message, in parallel: 
        // 1. Check if we meet the requirements
        // 2. Check signature validity and create a session
        if (isSuccess) {
            CreateSession()
            CheckWalletRequirements()
            setRequirementsLoading(true)
        }
    }, [isSuccess]);

    useEffect(() => {
        if (loggedIn && meetsRequirements) {
            setShowNextButton(true)
        }

    }, [loggedIn, meetsRequirements]);

    function signAndAlert() {
        signMessage({ message: messageToSign })
        alert.show("Please open your wallet to sign the login message", { type: 'info' })
    }


    // ----------------------------------------
    // ----------------------------------------
    // --------------- Helpers ----------------
    // ----------------------------------------
    // ----------------------------------------

    const SecondButton = () => {
        if (showNextButton) {
            return (<PrimaryButtonSmall text="Give Feedback" buttonType="button" marginClass="px-4" onClick={() => navigate(props.nextPath)} />)
        } else if (isConnected) {
            return (<ButtonOrLoadingMessage onClick={() => signAndAlert()} signatureLoading={isLoading} />)
        } else {
            return (<></>)
        }
    }



    return (
        <>
            <MissionDetailsHighlight>
                {props.description}
            </MissionDetailsHighlight>
            <VertSpacer />
            {props.missionStatus != "open" ? <ClosedMissionText /> :
                <>           <WalletRequirements company={props.companyName} requirements={props.walletRequirements} />
                    <div className="row gray-line" />
                    {!loggedIn ? <MissionDetailsConnectAndVerifyTextAndTooltip /> : <> </>}
                    <div className="d-flex flex-row align-items-start justify-content-center justify-content-sm-start flex-wrap column-gap-3 row-gap-3">
                        <ConnectButton showBalance={false} chainStatus="none" />
                        <SecondButton />
                        {requirementsLoading ? <div>"Loading..."</div> : <></>}
                    </div>
                </>}

        </>
    )

}

function ClosedMissionText() {

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="text-center">This feedback mission is now closed. Thank you for your interest.</div>
        </div>
    )
}


function ButtonOrLoadingMessage(props) {
    if (props.signatureLoading) {
        return (
            <SecondaryButtonSmall text="Verifying..." buttonType="button" marginClass="" />
        )
    } else {
        return (
            <PrimaryButtonSmall text="Sign to Verify" buttonType="button" marginClass="px-4" onClick={props.onClick} />
        )
    }
}

export default PMOverview;