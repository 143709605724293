import React from "react";
import { Link } from "react-router-dom";


function HeaderBasicNew(props) {
    const toRoute = (props.logoRoute == "homepage") ? "https://despark.io" : "/signin";
    if (props.logoRoute == "homepage") {
        return (
            <header>
                <div className="container-fluid p-0 ">
                    <div className="navbar navbar-new navbar-dark bg-black-color bottom-outline-gray">
                        <a href="https://despark.io" className="navbar-brand mx-4 px-2 my-3">
                            <strong className="logo-text-new">
                            </strong>
                        </a>
                    </div>
                </div>
            </header>
        )

    } else {
        return (
            <header>
                <div className="container-fluid p-0 ">
                    <div className="navbar navbar-new navbar-dark bg-black-color bottom-outline-gray">
                        <Link to={{ pathname: toRoute }} className="navbar-brand mx-4 px-2 my-3">
                            <strong className="logo-text-new">
                                de<span className="logo-span-highlight-new">spark</span>
                            </strong>
                        </Link>

                    </div>
                </div>
            </header>
        )
    }
}

export default HeaderBasicNew;