import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAlert } from 'react-alert';
import { MissionDetailsHighlight, VertSpacer } from "../../overview_components/CommonOverviewComponents";
import PublicSimpleScheduler from "./PublicSimpleScheduler";
import PublicScheduledSessionList from "./PublicScheduledSessionList";
import PublicSchedulePlaceholder from "./PublicSchedulePlaceholder";
import LoadingIcon from "../../standard_components/LoadingIcon";

// API Calls
import ScheduleAPI from "../../api/api_calls/ScheduleAPI";



// A slightly complicated component
// This can be in a few states:
// 1. The user is selecting a time.
// 2. The user has selected a time, and needs to give an email
// 3. The user has set up the session, and has the option to reschedule or cancel

// We're going to hit the API to pull back schedule details for this mission.
// We're going to BOTH ask for available times and for the user's current session status.
function PublicSchedule(props) {

    const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");


    const [timezone, setTimezone] = useState("");
    const [meetingUrl, setMeetingUrl] = useState("");
    const [startTime, setStartTime] = useState("");
    const [joinButtonActive, setJoinButtonActive] = useState(false);
    const [videoRequired, setVideoRequired] = useState(false);
    const [appointmentList, setAppointmentList] = useState({});

    const [availabilityLoaded, setAvailabilityLoaded] = useState(false);
    const [pageStatus, setPageStatus] = useState("loading");
    const [rescheduleClicked, setRescheduleClicked] = useState(false);


    const navigate = useNavigate();
    let params = useParams()
    const missionId = params.missionId;
    const alert = useAlert()


    // ----------------------------------------
    // ----------------------------------------
    // --------------- UseEffects ----------------
    // ----------------------------------------
    // ----------------------------------------

    useEffect(() => {
        setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)

        // Get back the current status here.
        // Includes: whether anything has been scheduled. If so, time and email. 
        // If not, the available times.
        async function GetSessionStatus() {
            const sessionData = await ScheduleAPI.getPublicScheduleStatus(missionId, Intl.DateTimeFormat().resolvedOptions().timeZone, navigate)
            if (sessionData.success) {
                setAvailabilityLoaded(true);
                setAppointmentList(sessionData.appointment_list);
                setVideoRequired(sessionData.video_required);
                setTimezone(sessionData.timezone)
                setEmail(sessionData.email)
                setName(sessionData.name)

                if (sessionData.scheduled == true) {
                    setPageStatus("scheduled")
                    const first_event = sessionData.upcoming_event
                    setSelectedDate(new Date(first_event.start_time).toLocaleDateString('en-us', { weekday: "long", month: "long", day: "numeric" }))
                    setSelectedTime(new Date(first_event.start_time).toLocaleTimeString('en-us', { hour: "numeric", minute: "numeric", hour12: true }))
                    setMeetingUrl(sessionData.meeting_url)
                    setStartTime(first_event.start_time)


                } else {
                    setPageStatus("unscheduled")
                }
            }
        }

        GetSessionStatus()
    }, []);


    // ----------------------------------------
    // ----------------------------------------
    // --------------- Helpers ----------------
    // ----------------------------------------
    // ----------------------------------------


    function setSelectedAppointment(appointmentId, newSelectedDate, newSelectedTime) {
        setSelectedAppointmentId(appointmentId)
        setSelectedDate(newSelectedDate)
        setSelectedTime(newSelectedTime)
        setRescheduleClicked(false)
        setPageStatus("appointmentSelected")
    }

    function onCancel() {
        
        async function CancelSession() {
            const cancelData = await ScheduleAPI.cancelUpcomingEvent(missionId, navigate)

            if (cancelData.success) {
                setPageStatus("unscheduled")
                alert.show("Session Canceled", { type: 'success' })

            } else {
                alert.show("There was an error cancelling your session. Please try again.", { type: 'error' })
            }
        }

        CancelSession()
    }

    function onReschedule() {
        setRescheduleClicked(true)
        setPageStatus("unscheduled")
    }

    function cancelReschedule() {
        setRescheduleClicked(false)
        setPageStatus("scheduled")
    }

    function SwitchComponentByPageStatus() {
        if (pageStatus == "loading") {
            return (
                <LoadingIcon />
            )
        } else if (pageStatus == "unscheduled") {
            if (Object.keys(appointmentList).length > 0) {
                return (
                    <PublicSimpleScheduler
                        appointmentList={appointmentList}
                        videoRequired={videoRequired}
                        timezone={timezone}
                        setSelectedAppointment={setSelectedAppointment}
                        missionId={missionId}
                        rescheduleClicked={rescheduleClicked}
                        cancelReschedule={cancelReschedule}
                        email={email}
                        name={name}
                        timeEstimate={props.timeEstimate}
                    />
                )
            } else {
                return (
                    <PublicSchedulePlaceholder 
                        rescheduleClicked={rescheduleClicked}
                        cancelReschedule={cancelReschedule}
                    />
                )
            }
        } else if (pageStatus == "scheduled") {
            return (
                <PublicScheduledSessionList
                    name={name}
                    email={email}
                    reschedule={onReschedule}
                    cancel={onCancel}
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    meetingUrl={meetingUrl}
                    startTime={startTime}
                    rescheduleClicked={rescheduleClicked}
                />
            )
        }
    }

    // ----------------------------------------
    // ----------------------------------------
    // --------------- Rendering ----------------
    // ----------------------------------------
    // ----------------------------------------

    return (
        <>
            <VertSpacer />
            { availabilityLoaded ? 
                <SwitchComponentByPageStatus /> : 
                <LoadingIcon /> 
            }
        </>
    )

}

export default PublicSchedule;