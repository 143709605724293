import React, { useState, useEffect, useCallback, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from 'react-alert';
import DateTimeSelects from "./DateTimeSelects";
import EmailSelect from "./EmailSelect";

// API Calls
import ScheduleAPI from "../../api/api_calls/ScheduleAPI";

function PublicSimpleScheduler(props) {
    const navigate = useNavigate()
    const alert = useAlert()

    const [selectedAppointmentId, setSelectedAppointmentId] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")


    const [selectingEmail, setSelectingEmail] = useState(false)
    const [submitting, setSubmitting] = useState(false)


    // ----------------------------------------
    // ----------------------------------------
    // --------------- UseEffects ----------------
    // ----------------------------------------
    // ----------------------------------------





    // ----------------------------------------
    // ----------------------------------------
    // --------------- Helpers ----------------
    // ----------------------------------------
    // ----------------------------------------

    function SelectAppointment(appointmentId, date, time) {
        if (!props.rescheduleClicked) {
            setSelectedAppointmentId(appointmentId)
            setSelectedDate(date)
            setSelectedTime(time)
            setSelectingEmail(true)
        } else {
            setSelectedAppointmentId(appointmentId)
            setSelectedDate(date)
            setSelectedTime(time)
            onSubmit(props.email, props.name, appointmentId)
        }
    }


    function onSubmit(input_email, input_name, appointmentId = null) {
        // Submit from here
        setEmail(input_email)
        setEmail(input_name)
        const updatedAppointmentId = appointmentId ? appointmentId : selectedAppointmentId
        
        async function submitAppointment(finalEmail, finalName, finalAppointmentId) {
            // Had to accept an input of appointmentId, because the state didn't update in time from SelectAppointment
            const response = await ScheduleAPI.submitAppointment(props.missionId, finalAppointmentId, finalEmail, finalName, navigate)

            if (response.success) {
                // Refresh the page
                navigate(0);
            } else {
                alert.show(response.error, { type: 'error'})
            }
            setSubmitting(false)
        }
        setSubmitting(true)
        submitAppointment(input_email, input_name, updatedAppointmentId )

    }

    // ----------------------------------------
    // ----------------------------------------
    // --------------- Rendering ----------------
    // ----------------------------------------
    // ----------------------------------------

    return (
        < >
            <div className="body-1">
                Please select a slot to {props.rescheduleClicked ? "re" : ""}schedule your {props.timeEstimate}-minute feedback session. Video is {props.videoRequired ? "" : "NOT "}required.
            </div>
            {selectingEmail ?
                <EmailSelect 
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    onSubmit={onSubmit}
                    setSelectingEmail={setSelectingEmail}
                    submitting={submitting}
                />
                :
                <DateTimeSelects 
                    timezone={props.timezone}
                    appointmentList={props.appointmentList}
                    onSelect={SelectAppointment}
                    submitting={submitting}
                    rescheduleClicked={props.rescheduleClicked}
                    cancelReschedule={props.cancelReschedule}
                />
            }

        </>
    )
}



export default PublicSimpleScheduler
