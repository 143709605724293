import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PrimaryButtonSmall, TertiaryButtonSmall } from "./CommonComponents";





// Reusable SlideContainer component
function SlideContainer({ children, handleSubmit, highlightPage, executeScroll }) {
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const updatedChildren = children.filter(child => child != null && child != undefined && child != false)


  const isLastSlide = currentSlideIndex === updatedChildren.length - 1;
  const isFirstSlide = currentSlideIndex === 0;

  // Take children and remove any empty or null children

  // useEffect(() => {
  //   const container = containerRef.current;
  //   const slideElements = container.getElementsByClassName('slide');
  //   let tallestSlideHeight = 0;

  //   for (let i = 0; i < slideElements.length; i++) {
  //     const slideHeight = slideElements[i].scrollHeight;
  //     if (slideHeight > tallestSlideHeight) {
  //       tallestSlideHeight = slideHeight;
  //     }
  //   }
  //   let totalSlideHeight = tallestSlideHeight + 20 + 10 //Roughly for buttons plus margin
  //   setContainerHeight(totalSlideHeight);
  // }, [highlightPage]);

  useEffect(() => {
    if (highlightPage >= 0) {
      updateSlideIndex(highlightPage);
    }
  }, [highlightPage]);

  function updateSlideIndex(newIndex) {
    setCurrentSlideIndex(newIndex);
    executeScroll();
  }


  const handleNextSlide = () => {
    !isLastSlide && updateSlideIndex(currentSlideIndex + 1);
  };

  const handlePreviousSlide = () => {
    !isFirstSlide && updateSlideIndex(currentSlideIndex - 1);
  };

  const handleSlideChange = (index) => {
    updateSlideIndex(index);
  };

  return (
    <div className="slide-container" ref={containerRef}>
      {updatedChildren.map((child, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlideIndex ? 'slide-active' : 'slide-inactive'}`}
        >
          {child}
        </div>
      ))}
      <div className="slide-navigation">
        {!isFirstSlide ? <TertiaryButtonSmall text="Back" onClick={handlePreviousSlide} buttonType="button" marginClass="mx-2" /> : <div></div>}
        <div className="slide-indicators">
          {updatedChildren.map((_, index) => (
            <div
              key={index}
              className={`slide-indicator ${index === currentSlideIndex ? 'active' : ''}`}
              onClick={() => handleSlideChange(index)}
            />
          ))}
        </div>
        {!isLastSlide && <PrimaryButtonSmall onClick={handleNextSlide} text="Next" buttonType="button" marginClass="mx-2" />}
        {isLastSlide && <PrimaryButtonSmall onClick={handleSubmit} text="Submit" buttonType="submit" marginClass="mx-2" />}
      </div>
    </div>
  );
};


// Reusable Slide component
function Slide({ children, questions }) {
  const [answers, setAnswers] = useState([]);

  const handleAnswerChange = (questionIndex, selectedOptions) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = selectedOptions;
    setAnswers(newAnswers);
  };

  return (
    <>
      {questions && questions.map((question, index) => (
        <Question
          key={index}
          question={question}
          selectedOptions={answers[index] || []}
          onAnswerChange={(selectedOptions) => handleAnswerChange(index, selectedOptions)}
        />
      ))}
      {children}
    </>
  );
};

// Reusable Question component
function Question({ question, selectedOptions, onAnswerChange }) {
  const handleOptionChange = (optionValue) => {
    const newSelectedOptions = selectedOptions.includes(optionValue)
      ? selectedOptions.filter((option) => option !== optionValue)
      : [...selectedOptions, optionValue];
    onAnswerChange(newSelectedOptions);
  };

  return (
    <div className="question">
      <h3>{question.text}</h3>
      {question.options.map((option, index) => (
        <div key={index} className="form-check">
          <input
            className="form-check-input"
            type={question.isMultipleChoice ? 'radio' : 'checkbox'}
            name={question.text}
            id={option.value}
            checked={selectedOptions.includes(option.value)}
            onChange={() => handleOptionChange(option.value)}
          />
          <label className="form-check-label" htmlFor={option.value}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};


export {
  SlideContainer, Slide, Question
}