import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from 'react-alert';
import Placeholder from "../menu_components/Placeholder";
import { UserContext } from "../../../UserContext";


import UserDemographicAPI from "../../api/api_calls/UserDemographicAPI";

function CardExtendedProfile(props) {
    const navigate = useNavigate();
    const alert = useAlert()
    const { user, setUser, refreshUser } = useContext(UserContext);



    // store the props in local state
    const [age, setAge] = useState((props.age || ""));
    const [education, setEducation] = useState((props.education || ""));
    const [industry, setIndustry] = useState((props.industry == [] || "0"));
    const [jobTitle, setJobTitle] = useState((props.jobTitle || ""));
    const [income, setIncome] = useState((props.income || ""));
    const [gender, setGender] = useState((props.gender || ""));
    const [loaded, setLoaded] = useState(props.loadExtendedComplete);
    let [propsAge, propsEducation, propsIndustry, propsJobTitle, propsIncome, propsGender, propsLoaded] = [age, education, industry, jobTitle, income, gender, loaded]

    // Checks if there are props that don't match most recent props,
    // If so, updates local state
    useEffect(() => {
        if (props.education !== propsEducation) {
            setEducation(props.education);
            propsEducation = props.education;
        }
        if (props.age !== propsAge) {
            setAge(props.age);
            propsAge = props.age;
        }
        if (props.industry !== null && props.industry.length != 0 && props.industry !== propsIndustry) {
            setIndustry(props.industry);
            propsIndustry = props.industry;
        }
        if (props.jobTitle !== propsJobTitle) {
            setJobTitle(props.jobTitle);
            propsJobTitle = props.jobTitle;
        }
        if (props.income !== propsIncome) {
            setIncome(props.income);
            propsIncome = props.income;
        }
        if (props.gender !== propsGender) {
            setGender(props.gender);
            propsGender = props.gender;
        }
        if (props.loadExtendedComplete !== propsLoaded) {
            setLoaded(props.loadExtendedComplete);
            propsLoaded = props.loadExtendedComplete;
        }
    }, [props.education, props.age, props.industry, props.jobTitle, props.income, props.gender, props.loadExtendedComplete]

    );



    const onChangeAge = (event) => {
        setAge(event.target.value);
    }
    const onChangeEducation = (event) => {
        setEducation(event.target.value);
    }
    const onChangeIndustry = (event) => {
        setIndustry(event.target.value);
    }
    const onChangeJobTitle = (event) => {
        setJobTitle(event.target.value);
        //setEducation(event.target.value);
    }
    const onChangeIncome = (event) => {
        setIncome(event.target.value);
        //setEducation(event.target.value);
    }
    const onChangeGender = (event) => {
        setGender(event.target.value);
        //setEducation(event.target.value);
    }

    //To change
    async function onSubmit(event) {
        event.preventDefault();

        const response = await UserDemographicAPI.update(age, education, industry, jobTitle, income, gender, navigate)

        if (response.success) {
            refreshUser();
            alert.show('Profile Updated',
                {
                    type: 'success'
                })
        }
    }

    return (
        <div className="card rounded-body half-screen-height no-outline bg-secondary-color  text-white my-3" >
            <div className="card-title no-outline px-1 py-1">
                <h3 className="card-header">
                    Extended Profile
                </h3>
            </div>
            <div className="card-body rounded-body bg-secondary-color text-white px-3 py-1">
                <div className="card-header-callout mb-4 ">
                    These optional questions will help you match additional missions.
                </div>
                {!loaded ? <Placeholder /> : (
                    <form onSubmit={onSubmit} className="form-control bg-transparent no-outline text-white">
                        <div className='form-group mb-4 form-width'>
                            <div className="row">
                                <div className="col-auto triangle-wrapper">
                                    <img loading="lazy" src="https://uploads-ssl.webflow.com/62f26ef25631b613f81e4201/6585ac97b5c6458219ee9b28_Right%20Triangle.svg" alt="" className="image-3" />
                                </div>
                                <div className="col">
                                    <label >Please enter in your age in years. </label>
                                    <input name="age" id="userAge" onChange={onChangeAge} className="old-style form-control bg-primary-color" placeholder="Enter Age" type="number"
                                        defaultValue={props.age}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-auto triangle-wrapper">
                                    <img loading="lazy" src="https://uploads-ssl.webflow.com/62f26ef25631b613f81e4201/6585ac97b5c6458219ee9b28_Right%20Triangle.svg" alt="" className="image-3" />
                                </div>
                                <div className="col">
                                    <label >What's the highest level of education you've achieved? </label>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={education == 1} type="radio" name="education" id="educationChoice1" onChange={onChangeEducation} value="1" />
                                        <label className="form-check-label" htmlFor="educationChoice1">
                                            Some High School
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={education == 2} type="radio" name="education" id="educationChoice2" onChange={onChangeEducation} value="2" />
                                        <label className="form-check-label" htmlFor="educationChoice2">
                                            High School
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={education == 3} type="radio" name="education" id="educationChoice3" onChange={onChangeEducation} value="3" />
                                        <label className="form-check-label" htmlFor="educationChoice3">
                                            Bachelor's Degree
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={education == 4} type="radio" name="education" id="educationChoice4" onChange={onChangeEducation} value="4" />
                                        <label className="form-check-label" htmlFor="educationChoice4">
                                            Master's Degree
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={education == 5} type="radio" name="education" id="educationChoice5" onChange={onChangeEducation} value="5" />
                                        <label className="form-check-label" htmlFor="educationChoice5">
                                            Ph.D. or higher
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={education == 6} type="radio" name="education" id="educationChoice6" onChange={onChangeEducation} value="6" />
                                        <label className="form-check-label" htmlFor="educationChoice6">
                                            Trade School
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={education == 7} type="radio" name="education" id="educationChoice7" onChange={onChangeEducation} value="7" />
                                        <label className="form-check-label" htmlFor="educationChoice7">
                                            Prefer not to say
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-auto triangle-wrapper">
                                    <img loading="lazy" src="https://uploads-ssl.webflow.com/62f26ef25631b613f81e4201/6585ac97b5c6458219ee9b28_Right%20Triangle.svg" alt="" className="image-3" />
                                </div>
                                <div className="col">
                                    <label >Which industry do you work in?</label>
                                    <select className="form-select custom-select no-outline" name="industry" onChange={onChangeIndustry} id="userIndustry" aria-label="Industry select" defaultValue={industry}>
                                        <option disabled value="0">Select industry</option>
                                        <option value="1">Self Employed</option>
                                        <option value="2">Unemployed</option>
                                        <option value="3">Web3 / Blockchain / Crypto</option>
                                        <option value="4">Accounting</option>
                                        <option value="5">Advertising</option>
                                        <option value="6">Aerospace/Defense</option>
                                        <option value="7">Agriculture</option>
                                        <option value="8">Business Services (not including Consulting)</option>
                                        <option value="9">Construction</option>
                                        <option value="10">Consulting</option>
                                        <option value="11">Consumer Goods and Durables</option>
                                        <option value="12">Consumer Services</option>
                                        <option value="13">Education</option>
                                        <option value="14">Energy and Public Utilities</option>
                                        <option value="15">Entertainment / Recreation / Gaming</option>
                                        <option value="16">Financial Services</option>
                                        <option value="17">Food Service</option>
                                        <option value="18">Government/Public Sector</option>
                                        <option value="19">Healthcare</option>
                                        <option value="20">Hospitality and Travel</option>
                                        <option value="21">Industrial Products</option>
                                        <option value="22">Internet / Web 2.0</option>
                                        <option value="23">Legal</option>
                                        <option value="24">Life Sciences (Pharmaceuticals, Medical Devices, Biotech)</option>
                                        <option value="25">Manufacturing</option>
                                        <option value="26">Marketing / Advertising / PR</option>
                                        <option value="27">Media / Printing / Publishing</option>
                                        <option value="28">Mining</option>
                                        <option value="29">Non-Profit</option>
                                        <option value="30">Private Equity/Public Equity/Venture Capital/Hedge Fund</option>
                                        <option value="31">Real Estate</option>
                                        <option value="32">Retail</option>
                                        <option value="33">Social Media / Creator</option>
                                        <option value="34">Student</option>
                                        <option value="35">Technology</option>
                                        <option value="36">Telecom</option>
                                        <option value="37">Transportation and Logistics</option>
                                        <option value="38">Other (please specify)</option>
                                        <option value="39">Prefer not to say</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-auto triangle-wrapper">
                                    <img loading="lazy" src="https://uploads-ssl.webflow.com/62f26ef25631b613f81e4201/6585ac97b5c6458219ee9b28_Right%20Triangle.svg" alt="" className="image-3" />
                                </div>
                                <div className="col">
                                    <label >What is your job title?</label>
                                    <input name="jobTitle" id="userJobTitle" onChange={onChangeJobTitle} className="old-style form-control bg-primary-color" placeholder="Enter Job Title" type="text"
                                        defaultValue={props.jobTitle} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-auto triangle-wrapper">
                                    <img loading="lazy" src="https://uploads-ssl.webflow.com/62f26ef25631b613f81e4201/6585ac97b5c6458219ee9b28_Right%20Triangle.svg" alt="" className="image-3" />
                                </div>
                                <div className="col">
                                    <label >What is your approximate household income? </label>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={income == 1} type="radio" name="income" id="incomeChoice1" onChange={onChangeIncome} value="1" />
                                        <label className="form-check-label" htmlFor="incomeChoice1">
                                            $0 - $24,999
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={income == 2} type="radio" name="income" id="incomeChoice2" onChange={onChangeIncome} value="2" />
                                        <label className="form-check-label" htmlFor="incomeChoice2">
                                            $25,000 - $49,999
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={income == 3} type="radio" name="income" id="incomeChoice3" onChange={onChangeIncome} value="3" />
                                        <label className="form-check-label" htmlFor="incomeChoice3">
                                            $50,000 - $74,999
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={income == 4} type="radio" name="income" id="incomeChoice4" onChange={onChangeIncome} value="4" />
                                        <label className="form-check-label" htmlFor="incomeChoice4">
                                            $75,000 - $99,999
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={income == 5} type="radio" name="income" id="incomeChoice5" onChange={onChangeIncome} value="5" />
                                        <label className="form-check-label" htmlFor="incomeChoice5">
                                            $100,000 - $149,999
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={income == 6} type="radio" name="income" id="incomeChoice6" onChange={onChangeIncome} value="6" />
                                        <label className="form-check-label" htmlFor="incomeChoice6">
                                            $150,000 - $199,999
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={income == 7} type="radio" name="income" id="incomeChoice7" onChange={onChangeIncome} value="7" />
                                        <label className="form-check-label" htmlFor="incomeChoice7">
                                            $200,000 and up
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={income == 8} type="radio" name="income" id="incomeChoice8" onChange={onChangeIncome} value="8" />
                                        <label className="form-check-label" htmlFor="incomeChoice8">
                                            Prefer not to say
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-auto triangle-wrapper">
                                    <img loading="lazy" src="https://uploads-ssl.webflow.com/62f26ef25631b613f81e4201/6585ac97b5c6458219ee9b28_Right%20Triangle.svg" alt="" className="image-3" />
                                </div>
                                <div className="col">
                                    <label >What gender do you identify as? </label>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={gender == 1} type="radio" name="gender" id="genderChoice1" onChange={onChangeGender} value="1" />
                                        <label className="form-check-label" htmlFor="genderChoice1">
                                            Man
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={gender == 2} type="radio" name="gender" id="genderChoice2" onChange={onChangeGender} value="2" />
                                        <label className="form-check-label" htmlFor="genderChoice2">
                                            Woman
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={gender == 3} type="radio" name="gender" id="genderChoice3" onChange={onChangeGender} value="3" />
                                        <label className="form-check-label" htmlFor="genderChoice3">
                                            Non-binary
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={gender == 4} type="radio" name="gender" id="genderChoice4" onChange={onChangeGender} value="4" />
                                        <label className="form-check-label" htmlFor="genderChoice4">
                                            Other
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={gender == 5} type="radio" name="gender" id="genderChoice5" onChange={onChangeGender} value="5" />
                                        <label className="form-check-label" htmlFor="genderChoice5">
                                            Prefer not to say
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <button type="submit" className='btn btn-primary-color btn-shadow text-white no-outline mx-2 px-5 my-3' color='white' size='lg'>
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )

}

// Moved to own component
// function Placeholder() {
//     return ( 
//         <div className="no-outline profile-style d-flex full-height align-items-center justify-content-center px-1 py-1">
//             <div className="">
//                 <PropagateLoader color="white" aria-label="Loading Spinner" />
//             </div>
//         </div>
//     )
// }


export default CardExtendedProfile;

