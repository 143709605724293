import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Survey, Model, StylesManager } from "survey-react";
import 'survey-react/defaultV2.min.css';

import { PrimaryButtonSmall } from "../../../preauth_portal/components/CommonComponents";
import LoadingIcon from "../../standard_components/LoadingIcon";

// API Calls
import SurveyAPI from "../../api/api_calls/SurveyAPI";





function PublicSurvey(props) {
    const [survey, setSurvey] = useState(null);
    const [surveyComplete, setSurveyComplete] = useState(false);
    const [failedLoading, setFailedLoading] = useState(false);
    const [surveyLoaded, setSurveyLoaded] = useState(false);
    const [surveyFailed, setSurveyFailed] = useState(false);
    const [surveyId, setSurveyId] = useState(null);
    const stateRef = useRef();

    stateRef.current = surveyId;


    let params = useParams()
    const missionId = params.missionId;
    StylesManager.applyTheme("defaultV2");
    const navigate = useNavigate();



    // On complete, save the survey results
    const onSurveyComplete = useCallback(async (sender) => {

        setSurveyComplete(true);

        const response = await SurveyAPI.createSurveyResponse(
            missionId,
            stateRef.current, // This lets us send the screenerId to the API call (otherwise it's stale and null)
            sender.data, // This is the survey data
            navigate
        )
        navigate(response.next_path)


    }, []);

    // Load the survey
    useEffect(() => {
        // Grab information on this user
        async function GetSurvey() {
            const data = await SurveyAPI.getSurvey(missionId, "survey", navigate);

            if (data.success == false) {
                setFailedLoading(true);
                return;
            }
            // Once it loads, then run through and set all the survey stuff
            const surveyData = await data.survey;
            // Manually turn off autofocus for these.
            // Not working, but based on https://surveyjs.answerdesk.io/ticket/details/t8403/first-question-gets-focused-automatically
            // surveyData["focusFirstQuestionAutomatic"] = false;
            const tempSurvey = new Model(surveyData);
            tempSurvey.onComplete.add(onSurveyComplete);
            setSurvey(tempSurvey);
            setSurveyLoaded(true);
            setSurveyId(data.survey_id);
        }

        GetSurvey();
    }, []);


    return (
        <>
            <div className="">
                Please complete the survey below.
            </div>
            <SwitchCompleteAndFailedSurvey failedLoading={failedLoading} surveyComplete={surveyComplete} surveyLoaded={surveyLoaded} survey={survey} surveyFailed={surveyFailed} navigate={navigate} missionId={missionId} />
        </>
    )

}

function SwitchCompleteAndFailedSurvey(props) {
    const lastRoute = "/public/missions/" + props.missionId;
    if (props.failedLoading) {
        return (
            <div className="surveyComplete d-flex flex-column align-items-center justify-content-center my-4 py-5">
                <div className="pt-4">
                    Survey not available at this time.
                </div>
                <PrimaryButtonSmall text="Back to Overview" buttonType="button" onClick={() => props.navigate(lastRoute)} />
            </div>
        )
    } else if (props.surveyFailed) {
        return (
            <div className="surveyComplete d-flex flex-column align-items-center justify-content-center my-4 py-5">
                <div className="pt-4">
                    Unfortunately, you aren't the target audience for this feedback session. Thank you for your interest.
                </div>
                <PrimaryButtonSmall text="Back to Overview" buttonType="button" onClick={() => props.navigate(lastRoute)} />

            </div>
        )
    } else if (props.surveyComplete) {
        return (
            <div className="surveyComplete d-flex flex-column align-items-center justify-content-center my-4 py-5">
                <div className="pt-4">
                    Thank you for completing the survey. Saving your responses...
                </div>
            </div>
        )
    } else {
        return (
            <div className="black-bg-survey" id="surveyElement">
                {props.surveyLoaded ? <Survey model={props.survey} /> : <LoadingIcon />}
            </div>
        )
    }
}



export default PublicSurvey